import React from 'react';
import { Alert, Box, Radio, Text, Title } from '@nimbus-ds/components';
import { ErrorMessage, MultilineText } from 'commons/components';
import { useTranslationWithPrefix } from 'commons/utils';
import { InstallmentSectionInactive } from '../shared/InstallmentSectionInactive';
import { ResponsibleStatus } from '../useInstallmentsV2';
import WithInterestSkeleton from './WithInterestSkeleton';

interface WithInterestInterface {
  hasError: boolean;
  openSwitchModal: () => void;
  status: ResponsibleStatus;
}

function WithInterest({
  hasError,
  openSwitchModal,
  status,
}: WithInterestInterface): JSX.Element {
  const { t } = useTranslationWithPrefix('installments.withInterest');

  if (hasError)
    return (
      <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
        <Title as="h4">{t('title')}</Title>
        <ErrorMessage />
      </Box>
    );

  if (status === 'changing_to_customer') {
    return (
      <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
        <Box display="flex" justifyContent="space-between">
          <Title as="h4">{t('title')}</Title>
          <Radio name="with-interest" checked={false} disabled readOnly />
        </Box>
        <Text color="neutral-textHigh">{t('activeSubtitle')}</Text>
        <Alert show appearance="neutral">
          {t('changeInProgress')}
        </Alert>
      </Box>
    );
  }

  if (status === 'changing_to_merchant') {
    return (
      <InstallmentSectionInactive translationPrefix="installments.withInterest" />
    );
  }

  if (status === 'customer_active')
    return (
      <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
        <Box display="flex" justifyContent="space-between">
          <Title as="h4">{t('title')}</Title>
          <Radio name="with-interest" checked={true} readOnly />
        </Box>
        <MultilineText color="neutral-textHigh">
          {t('activeSubtitle')}
        </MultilineText>
      </Box>
    );

  return (
    <InstallmentSectionInactive
      translationPrefix="installments.withInterest"
      openSwitchModal={openSwitchModal}
    />
  );
}

WithInterest.Skeleton = WithInterestSkeleton;
export default WithInterest;
