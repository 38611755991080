import React, { useCallback, useContext, useEffect, useState } from 'react';
import { track } from '@amplitude/analytics-browser';
import { Box, Input } from '@nimbus-ds/components';
import { useTranslationWithPrefix } from 'commons/utils';
import DashboardContext, {
  DashboardContextArgs,
} from '../../Dashboard.context';
import DashboardFiltersContext, {
  DashboardFiltersContextInterface,
} from '../filter/DashboardFiltersContext';

type searchBarArgs = {
  onChange: (filters: filterObject) => void;
};

function SearchBar({ onChange }: searchBarArgs): JSX.Element {
  const [filter, setFilter] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');
  const { showSkeleton }: DashboardContextArgs = useContext(DashboardContext);
  const { setPage } = useContext(DashboardFiltersContext);

  const { setCustomSearch, customSearch }: DashboardFiltersContextInterface =
    useContext(DashboardFiltersContext);

  const { t } = useTranslationWithPrefix('dashboard.search');

  const callFilter = useCallback(
    (searchValue: string) => {
      track('nuvempago_dashboard_research_click');
      setFilter(searchValue);
      const searchFilter = filterBuilder(searchValue);
      setCustomSearch(Object.values(searchFilter));
      setPage(1);
      onChange(filterFormat(searchFilter));
    },
    [onChange, setCustomSearch, setPage],
  );

  const onPressingKey = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      setInputValue('');
      callFilter(e.currentTarget.value);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setInputValue(e.target.value);

  useEffect(() => {
    const customSearchStr = customSearch?.join(' ');

    if (filter && filter !== customSearchStr) {
      setFilter(customSearchStr ?? '');
      callFilter(customSearchStr ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callFilter, customSearch]);

  return (
    <Box width={'100%'}>
      {showSkeleton ? (
        <Input.Skeleton data-testid="search-bar-dashboard-skeleton" />
      ) : (
        <Input.Search
          placeholder={inputValue || t('placeHolder')}
          value={inputValue}
          onKeyDownCapture={onPressingKey}
          onChange={handleSearch}
          data-testid="searchBarDashboard"
        />
      )}
    </Box>
  );
}

const setDocument = (
  searchString: string,
  filterObject: filterObject,
): searchHandle => {
  const document = new RegExp(
    /(\d{2,3}\.\d{3}\.\d{3}((\/\d{4}))?-\d{2})+/gm,
  ).exec(searchString);

  if (document) filterObject.document = document[0];

  return {
    newSearchStr: document
      ? searchString.replace(document[0], '')
      : searchString,
    filterObject,
  };
};

const setAmount = (
  searchString: string,
  filterObject: filterObject,
): searchHandle => {
  const amount =
    /\d{1,10}(?:\.\d{3}){0,3}?,\d{2}/m.exec(searchString) ?? // 0000,00 and 0.000,00
    /\d{1,10}\.\d{2}/m.exec(searchString); // 0000.00

  if (amount) filterObject.paymentAmount = amount[0];

  return {
    newSearchStr: amount ? searchString.replace(amount[0], '') : searchString,
    filterObject,
  };
};

const setCustomer = (
  searchString: string,
  filterObject: filterObject,
): searchHandle => {
  const customer = new RegExp(/([A-Za-z]+){1}(\s[A-Za-z]+)?.*/m).exec(
    searchString,
  );
  if (customer) filterObject.customer = customer[0];

  return {
    newSearchStr: customer
      ? searchString.replace(customer[0], '')
      : searchString,
    filterObject,
  };
};

const setOrderType = (
  searchString: string,
  filterObject: filterObject,
): searchHandle => {
  const orderType = new RegExp(/\b(link|venda)\b/i).exec(searchString);

  if (orderType) filterObject.orderType = orderType[0];

  return {
    newSearchStr: orderType
      ? searchString.replace(orderType[0], '')
      : searchString,
    filterObject,
  };
};

const setOrderNumber = (
  searchString: string,
  filterObject: filterObject,
): searchHandle => {
  const orderNumber = new RegExp(/\d+/g).exec(searchString);

  if (orderNumber) filterObject.orderNumber = orderNumber[0];

  return {
    newSearchStr: orderNumber
      ? searchString.replace(orderNumber[0], '')
      : searchString,
    filterObject,
  };
};

export type filterObject = {
  orderNumber?: string;
  orderType?: string;
  document?: string;
  paymentAmount?: string;
  customer?: string;
};

interface searchHandle {
  newSearchStr: string;
  filterObject: filterObject;
}

function removeAmountPrefix(searchString: string): string {
  return searchString.replace(/(r\$ ?|R\$ ?)/gm, '');
}

function filterBuilder(searchString = '') {
  let filter: filterObject = {};

  searchString = removeAmountPrefix(searchString);

  const searchHandles = [
    setOrderType,
    setDocument,
    setAmount,
    setOrderNumber,
    setCustomer,
  ];

  searchHandles.forEach((searchHandle) => {
    const { newSearchStr, filterObject } = searchHandle(searchString, filter);
    searchString = newSearchStr;
    filter = filterObject;
  });

  return filter;
}

function filterFormat(filter: filterObject) {
  if (filter.orderNumber) {
    filter.orderNumber = filter.orderNumber.replace(/\D/g, '');
  }
  if (filter.paymentAmount) {
    filter.paymentAmount = filter.paymentAmount.replace(/\D/g, '');
  }
  if (filter.document) {
    filter.document = filter.document.replace(/\D/gm, '');
  }
  if (filter.orderType) {
    if (filter.orderType.toLocaleLowerCase() == 'link')
      filter.orderType = 'payment_link';
    else if (filter.orderType.toLocaleLowerCase() == 'venda')
      filter.orderType = 'order';
  }

  return filter;
}

export default SearchBar;
