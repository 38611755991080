import React from 'react';
import { getRelativeDateFromToday, todayDate } from 'commons/utils';
import DateRangeFilterEnum from 'domains/Brazil/commons/types/DateRangeFilterEnum';
import StatusFilterEnum from 'domains/Brazil/commons/types/TransactionStatusFilterEnum';
import TypeFilterEnum from 'domains/Brazil/commons/types/TransactionTypeFilterEnum';

export interface DashboardFiltersContextInterface {
  type: TypeFilterEnum;
  setType: React.Dispatch<React.SetStateAction<TypeFilterEnum>>;
  dateRange: DateRangeFilterEnum;
  setDateRange: React.Dispatch<React.SetStateAction<DateRangeFilterEnum>>;
  status: StatusFilterEnum;
  setStatus: React.Dispatch<React.SetStateAction<StatusFilterEnum>>;
  fromDate: string | undefined;
  setFromDate: React.Dispatch<React.SetStateAction<string | undefined>>;
  toDate: string | undefined;
  setToDate: React.Dispatch<React.SetStateAction<string | undefined>>;
  customSearch: string[] | undefined;
  setCustomSearch: React.Dispatch<React.SetStateAction<string[] | undefined>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  itemsPerPage: number;
}

export default React.createContext<DashboardFiltersContextInterface>({
  type: TypeFilterEnum.ALL,
  setType: () => {},
  dateRange: DateRangeFilterEnum.ALL,
  setDateRange: () => {},
  status: StatusFilterEnum.ALL,
  setStatus: () => {},
  fromDate: todayDate(),
  setFromDate: () => {},
  toDate: getRelativeDateFromToday(1),
  setToDate: () => {},
  customSearch: undefined,
  setCustomSearch: () => {},
  page: 1,
  setPage: () => {},
  itemsPerPage: 50,
});
