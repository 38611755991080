/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/jsx-key */

import React, { useCallback, useContext } from 'react';
import { useEffect, useState } from 'react';
import { Box } from '@nimbus-ds/components';
import dashboardService, {
  ChargesDashboardTransaction,
} from 'commons/services/dashboardServiceV2/dashboard.service';
import {
  ApiFilter,
  filterBuilder,
} from 'commons/services/dashboardServiceV2/filter.builder';
import { useTranslationWithPrefix } from 'commons/utils';
import FilterStatus from 'domains/Brazil/commons/types/TransactionStatusFilterEnum';
import RenderOnError from '../../../../commons/components/RenderOnError';
import DashboardContext, {
  DashboardContextArgs,
} from '../../../Dashboard.context';
import EmptyList from '../../list/EmptyList';
import EmptyState from '../../list/EmptyState';
import MobileListItem from '../../list/mobile/ListItem';
import { filterObject } from '../../search/SearchBar';
import MobileList from '../mobile/MobileList';

function RenderOnLoad(props: { children: JSX.Element; onLoad: boolean }) {
  if (!props.onLoad) return props.children;

  return (
    <Box>
      <DashboardListMobileSkeleton />
    </Box>
  );
}

export type filters = {
  date?: {
    from: string | undefined;
    to: string | undefined;
  };
  paymentMethod?: string;
  status?: FilterStatus;
  withOrderNumber?: boolean;
  count?: number;
  page?: number;
  itemsPerPage?: number;
  stringQuery?: string;
};

type dashboardListArgs = {
  filters?: filters & filterObject;
};

function DashboardListMobile({ filters }: dashboardListArgs): JSX.Element {
  const [listItens, setListItens] = useState<ChargesDashboardTransaction[]>([]);
  const [itemsCount, setItemsCount] = useState<number>(0);
  const [errorOnLoad, setError] = useState<boolean>(false);
  const [isFilteringTransactions, setFilteringTransactions] =
    useState<boolean>(false);
  const [isFetchingNextPage, setFetchingNextPage] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);

  const {
    showSkeleton,
    setLoadingTransactions,
    setHasTransactionWithoutFilters,
  }: DashboardContextArgs = useContext(DashboardContext);

  const { t } = useTranslationWithPrefix('dashboard.errorHandler');

  const fetch = useCallback(
    (
      setCallback: (transactions: ChargesDashboardTransaction[]) => void,
      apiFilters: Partial<ApiFilter>,
    ) => {
      dashboardService
        .getTransactions(apiFilters)
        .then((response) => {
          if (response.transactions.length) {
            setHasTransactionWithoutFilters(true);
          }
          setItemsCount(response.itemsCount);
          setPage(response.page);
          setCallback(response.transactions);
          setError(false);
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => {
          setFilteringTransactions(false);
          setLoadingTransactions(false);
          setFetchingNextPage(false);
        });
    },
    [setHasTransactionWithoutFilters, setLoadingTransactions],
  );

  const refreshHandler = useCallback(() => {
    setError(false);
    setFilteringTransactions(true);
    fetch(setListItens, filterBuilder(filters));
  }, [fetch, filters]);

  useEffect(() => {
    let apiFilters;
    if (filters) apiFilters = filterBuilder(filters);
    if (apiFilters) {
      setFilteringTransactions(true);
      fetch(setListItens, apiFilters);
    }
  }, [fetch, filters]);

  const handleOnEndScroll = useCallback(() => {
    const hasMoreItems = listItens?.length < itemsCount;
    const pushItems = (items: ChargesDashboardTransaction[]) =>
      setListItens((listItens) => [...listItens, ...items]);

    if (!isFetchingNextPage && !errorOnLoad && hasMoreItems) {
      setFetchingNextPage(true);
      fetch(pushItems, filterBuilder({ ...filters, page: page + 1 }));
    }
  }, [
    listItens,
    itemsCount,
    fetch,
    filters,
    page,
    isFetchingNextPage,
    errorOnLoad,
  ]);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } =
        document.documentElement;
      if (scrollTop + clientHeight >= scrollHeight - 20) {
        handleOnEndScroll();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleOnEndScroll]);

  const RenderTransactions = useCallback(
    (props: { listItens: ChargesDashboardTransaction[] }): JSX.Element => {
      if (isFilteringTransactions) return <DashboardListMobileSkeleton />;
      if (props.listItens.length) {
        return <MobileList listItens={props.listItens} />;
      }
      if (isFetchingNextPage) return <DashboardListMobileSkeleton />;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { page, items, ...restFilters } = filterBuilder(filters);
      const hasNoFilters = Object.keys(restFilters).length === 0;
      return hasNoFilters ? <EmptyState /> : <EmptyList />;
    },
    [filters, isFilteringTransactions, isFetchingNextPage],
  );

  return (
    <Box data-testid="dashboard-list-mobile-v2">
      <RenderOnError
        message={t('message')}
        label={t('label')}
        error={errorOnLoad}
        refreshHandler={refreshHandler}
      >
        <RenderOnLoad onLoad={showSkeleton}>
          <>
            <RenderTransactions listItens={listItens} />
            {isFetchingNextPage && <DashboardListMobileSkeleton />}
          </>
        </RenderOnLoad>
      </RenderOnError>
    </Box>
  );
}

function DashboardListMobileSkeleton(): JSX.Element {
  return (
    <Box justifyContent={'space-between'} data-testid="Dashboard-List-Skeleton">
      {GenerateSkeletonList(MobileListItem.Skeleton, 4)}
    </Box>
  );
}

function GenerateSkeletonList(
  SkeletonComponent: () => JSX.Element,
  count = 10,
): JSX.Element[] {
  const itensMap: JSX.Element[] = [];
  for (let i = 0; i < count; i++) {
    itensMap.push(<SkeletonComponent key={`list-item-skeleton:${i}`} />);
  }
  return itensMap;
}

export default DashboardListMobile;
