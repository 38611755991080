/* eslint-disable max-len */
import React from 'react';
import { Box } from '@nimbus-ds/components';
import {
  ChargesDashboardTransaction,
  Origin,
} from 'commons/services/dashboardServiceV2/dashboard.service';
import { getStatusTagCode } from 'commons/services/dashboardServiceV2/status-tag.service';
import { OrderType } from 'commons/types/OrderType.enum';
import MobileListItem from './ListItem';

interface listArgs {
  listItens: Omit<
    ChargesDashboardTransaction,
    'updatedAt' | 'installments' | 'failureCode'
  >[];
}

function MobileList({ listItens }: listArgs): JSX.Element {
  return (
    <Box data-testid="MobileList">
      {listItens.map((item) => {
        const type =
          item?.origin === Origin.PaymentLink
            ? OrderType.PaymentLink
            : OrderType.Checkout;
        const clientName = item?.customer?.name;
        const amount = Number(item?.amount?.value).toFixed(2);
        const status = getStatusTagCode(item.status, item.failureReason);
        const orderNumber =
          item?.orderNumber > 0 ? item?.orderNumber : undefined;
        const orderId = item.id.toString();

        return (
          <MobileListItem
            key={item.id}
            type={type}
            date={item.createdAt}
            orderId={orderId ?? ''}
            orderNumber={orderNumber?.toString()}
            clientName={clientName}
            amount={amount}
            status={status}
          />
        );
      })}
    </Box>
  );
}

export default MobileList;
