import { useEffect } from 'react';
import React from 'react';
import { useHistory } from 'react-router';
import { track } from '@amplitude/analytics-browser';
import {
  Box,
  Button,
  Icon,
  Text,
  Textarea,
  Thumbnail,
  Title,
} from '@nimbus-ds/components';
import { PlusCircleIcon } from '@nimbus-ds/icons';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import nexo from 'App/nexoClient';
import { ROUTES } from 'App/Routes/constants';
import { useIsMobile } from 'commons/hooks';
import { useTranslationWithPrefix } from 'commons/utils';
import AppLayout from 'domains/Brazil/AppLayout/AppLayout';
import NuvemPagoCta from 'domains/Brazil/commons/components/NuvemPagoCta';

const HIDE_INTRO_LOCALSTORAGE_KEY = 'nuvempagoBrHidePaymentLinkIntro';

function shouldHideIntro(): boolean {
  try {
    return localStorage.getItem(HIDE_INTRO_LOCALSTORAGE_KEY) === 'true';
  } catch (error) {
    // localStorage may not be defined, is blocked by user settings, or reached its capacity
    return false;
  }
}

function hideEmptyState() {
  try {
    localStorage.setItem(HIDE_INTRO_LOCALSTORAGE_KEY, 'true');
  } catch (error) {
    return;
  }
}

function PaymentLinkNotification(): JSX.Element {
  const { t, i18n } = useTranslationWithPrefix('paymentLink.intro');
  const { t: navT } = useTranslationWithPrefix('navigation');
  const isMobile = useIsMobile();
  const history = useHistory();

  const redirectToForm = () => {
    track('nuvempago_payment_link_emptystate_new_link');
    history.push(ROUTES.PAYMENT_LINK_FORM);
  };

  useEffect(() => {
    if (shouldHideIntro()) history.push(ROUTES.PAYMENT_LINK_FORM);
    else hideEmptyState();
  }, [history]);

  useEffect(() => {
    navigateHeader(nexo, { goTo: ROUTES.DASHBOARD, text: navT('back') });
    i18n?.changeLanguage('pt-BR');
  }, [i18n, navT]);

  const backgroundImg = () => {
    return (
      <img
        src={`/assets/img/payment-link-logo-${
          isMobile ? 'mobile' : 'desktop'
        }.png`}
        width={'100%'}
        alt=""
      />
    );
  };

  const createLinkButton = () => {
    return (
      <Box display="flex" justifyContent={isMobile ? 'center' : 'flex-start'}>
        <Button onClick={redirectToForm} appearance="primary">
          <Icon color="neutral-background" source={<PlusCircleIcon />} />
          <Text fontWeight="bold" color="neutral-background" fontSize="base">
            {t('createLinkButton')}
          </Text>
        </Button>
      </Box>
    );
  };

  const linkDescription = () => {
    return (
      <Title
        as={isMobile ? 'h3' : 'h1'}
        color="primary-textHigh"
        textAlign={isMobile ? 'center' : 'left'}
      >
        {t('description')}
      </Title>
    );
  };

  const linkSubDescription = () => {
    return (
      <Text
        fontSize="base"
        lineHeight="base"
        color="neutral-textHigh"
        textAlign={isMobile ? 'center' : 'left'}
      >
        {t('subDescription')}
      </Text>
    );
  };

  const pageTitle = () => (
    <Title as="h1" color="primary-textHigh">
      {t('title')}
    </Title>
  );

  const mobile = () => {
    return (
      <Box
        display="grid"
        justifyContent="center"
        flexDirection="row"
        alignItems="stretch"
        gap="4"
        gridTemplateRows="auto auto auto 1fr auto"
        padding="2"
      >
        {pageTitle()}
        {backgroundImg()}
        {linkDescription()}
        {linkSubDescription()}
        {createLinkButton()}
        <NuvemPagoCta
          href={
            'https://atendimento.nuvemshop.com.br/pt_BR/configuracao-nuvem-pago/como-criar-um-link-de-pagamento-do-nuvem-pago'
          }
          title={t('cta')}
        />
      </Box>
    );
  };

  const desktop = () => {
    return (
      <Box
        padding="4"
        display="grid"
        alignItems="center"
        gridTemplateRows="auto 0.3fr"
      >
        {pageTitle()}
        <Box
          display="grid"
          gap="none"
          gridTemplateColumns="1fr 1fr"
          gridTemplateRows="1fr auto"
          alignItems="center"
          gridTemplateAreas="'empty-link-state-img payment-link-button' 'more-info more-info'"
          justifyContent="center"
        >
          {backgroundImg()}
          <Box
            display="grid"
            gap="4"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            {linkDescription()}
            {linkSubDescription()}
            {createLinkButton()}
          </Box>
        </Box>
        <Box display="grid">
          <NuvemPagoCta
            href={
              'https://atendimento.nuvemshop.com.br/pt_BR/configuracao-nuvem-pago/como-criar-um-link-de-pagamento-do-nuvem-pago'
            }
            title={t('cta')}
          />
        </Box>
      </Box>
    );
  };

  return <AppLayout>{isMobile ? mobile() : desktop()}</AppLayout>;
}

function PaymentLinkNotificationSkeleton(): JSX.Element {
  const isMobile = useIsMobile();

  const mobile = () => {
    return (
      <AppLayout>
        <Title.Skeleton width="100%" />
        <Box display="grid" gap="4" justifyContent="center">
          <Thumbnail.Skeleton width="300px" aspectRatio="4/3" />
          <Textarea.Skeleton width="300px" />
        </Box>
      </AppLayout>
    );
  };

  const desktop = () => {
    return (
      <AppLayout>
        <Title.Skeleton width="350px" />
        <Box display="grid" alignItems="center" gridTemplateRows="auto 0.3fr">
          <Box
            display="grid"
            gap="none"
            gridTemplateColumns="2fr 1fr"
            gridTemplateRows="1fr auto"
            alignItems="center"
            gridTemplateAreas="'empty-link-state-img payment-link-button' 'more-info more-info'"
            justifyContent="center"
          >
            <Thumbnail.Skeleton width="700px" aspectRatio="16/9" />
            <Box
              display="grid"
              gap="4"
              alignItems="stretch"
              justifyContent="center"
            >
              <Textarea.Skeleton width="300px" />
              <Textarea.Skeleton width="300px" />
              <Textarea.Skeleton width="300px" />
            </Box>
          </Box>
        </Box>
      </AppLayout>
    );
  };
  return isMobile ? mobile() : desktop();
}

PaymentLinkNotification.Skeleton = PaymentLinkNotificationSkeleton;

export default PaymentLinkNotification;
