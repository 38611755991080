import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DateRangeType } from 'commons/types';

export type SalesFilter = {
  salesPage?: number;
  search?: string;
  status?: string;
  fromDate?: string;
  toDate?: string;
  dateRange?: DateRangeType;
};

const parseQueryParamsToFilters = (search: string): SalesFilter => {
  const params = new URLSearchParams(search);

  const dateRange = (params.get('dateRange') as DateRangeType) ?? undefined;
  return {
    salesPage: Number(params.get('salesPage') ?? '1'),
    search: params.get('search') ?? '',
    status: params.get('status') ?? '',
    fromDate: params.get('fromDate') ?? '',
    toDate: params.get('toDate') ?? '',
    dateRange,
  };
};

const updateQueryString = (
  currentSearch: string,
  newFilters: SalesFilter,
): string => {
  const params = new URLSearchParams(currentSearch);

  Object.entries(newFilters).forEach(([key, value]) => {
    if (value) {
      params.set(key, value.toString());
    } else {
      params.delete(key);
    }
  });

  return params.toString();
};

export const useGetSalesFilter = () => {
  const history = useHistory();
  const location = useLocation();

  const filters = useMemo(
    () => parseQueryParamsToFilters(location.search),
    [location.search],
  );

  const updateFilters = useCallback(
    (newFilters: SalesFilter) => {
      const updatedQueryString = updateQueryString(location.search, newFilters);

      history.push({ search: `?${updatedQueryString}` });
    },
    [history, location.search],
  );

  return {
    filters,
    updateFilters,
  };
};
