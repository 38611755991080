export enum FeatureName {
  OnBoarding = 'OnBoarding',
  FutureIncomes = 'FutureIncomes',
  MinInstallmentValue = 'MinInstallmentValue',
  OnBoardingConfigurationModal = 'OnBoardingConfigurationModal',
  TransactionDetailsRefundReceiptLink = 'TransactionDetailsRefundReceiptLink',
  RefundReceiptDownload = 'RefundReceiptDownload',
  MinOrderAmount = 'MinOrderAmount',
}

export type GetFeatureDiscoveryResponse = {
  featureName: FeatureName;
  discovered: boolean;
};
