import React, { useContext, useMemo } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { track } from '@amplitude/analytics-browser';
import {
  Box,
  Button,
  Modal,
  Sidebar,
  Spinner,
  Text,
  Title,
  ToastProvider,
} from '@nimbus-ds/components';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import nexo from 'App/nexoClient';
import { ROUTES } from 'App/Routes/constants';
import ShowComponent from 'commons/components/FeatureFlag/ShowWithFeature';
import { useIsMobile } from 'commons/hooks';
import { FeatureFlag } from 'commons/services';
import { GetRefundDetailsResponse } from 'commons/services/refundService';
import { MoneyObjectInterface } from 'commons/types';
import { FailureCode, useTranslationWithPrefix } from 'commons/utils';
import MobileTopNavBar from '../commons/components/MobileTopNavBar';
import NuvemPagoCta from '../commons/components/NuvemPagoCta';
import RenderOnError from '../commons/components/RenderOnError';
import { isCreditCard, isPix } from '../commons/utils/paymentMethod';
import {
  NotificationBRContext,
  NotificationType,
} from '../Notifications/Notification.context';
import DetailAlert from '../PaymentLink/pages/Details/components/DetailAlert';
import {
  PaymentCard,
  PaymentCardInterface,
} from './components/PaymentCard/PaymentCard';
import ListProductInfo from './components/ProductInfo/ListProductInfo';
import RefundAmountCard from './components/RefundAmountCard/RefundAmountCard';
import RestockCard from './components/RestockCard/RestockCard';
import { ResumeCard } from './components/ResumeCard/ResumeCard';

export enum Caller {
  PaymentLinkRefund = 'PAYMENT_LINK_REFUND',
  TransactionRefund = 'TRANSACTION_REFUND',
}

export enum ActionType {
  SETUP_REFUND = 'SETUP_REFUND',
  REFUND_PARTIAL = 'REFUND_PARTIAL',
  REFUND_TOTAL = 'REFUND_TOTAL',
}
interface RefundArgs {
  caller: Caller;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  refundId: string;
  data?: GetRefundDetailsResponse;
  onRefund: (amount: MoneyObjectInterface, restock?: boolean) => any;
  confirmationDelaySeconds?: number;
}

interface CustomAlertProps {
  title: string;
  message: string;
  linkTitle?: string;
  linkUrl?: string;
  appearance:
    | 'primary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'neutral'
    | undefined;
  testid: string;
}

const isInvalidOrderNumber = (orderNumber: string | number = 0) =>
  Number(orderNumber) <= 0;

const formatOrderNumber = (orderNumber?: string | number) => {
  if (isInvalidOrderNumber(orderNumber)) return '';
  return padOrderNumber(orderNumber);
};

const padOrderNumber = (o?: string | number) => {
  return String(o).padStart(3, '0');
};

const mergePaymentData = (
  data: GetRefundDetailsResponse,
): PaymentCardInterface => ({
  name: data.consumer.name,
  holder_name: data.card?.holderName ?? data.consumer.name,
  document: data.consumer.document,
  brand: data.card?.brand,
  last_four_digits: data.card?.lastFourDigits,
  installments: data.installments,
  paymentMethod: data.paymentMethod,
});

function Refund(props: RefundArgs): JSX.Element | null {
  const isMobile = useIsMobile();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [buttonUrlCancel, setButtonUrlCancel] = useState('');
  const [requestRefundAmount, setRequestRefundAmount] = useState(0);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [isValidInputAmount, setIsValidInputAmount] = useState(false);
  const [isTotalRefund, setIsTotalRefund] = useState(false);
  const [isLoadingConfirmation, setLoadingConfirmation] = useState(false);
  const [isRefundSuccessful, setRefundSuccessful] = useState<boolean>();
  const [restock, setRestock] = useState(true);
  const { t } = useTranslationWithPrefix('refund');
  const { t: navT } = useTranslationWithPrefix('navigation');
  const history = useHistory();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const handleOpenConfirmation = () =>
    setOpenConfirmation((prevState) => !prevState);

  const refundDetailsData = props.data;

  const setupAmplitudeTag = useMemo(() => {
    if (props.caller == Caller.PaymentLinkRefund) {
      if (isTotalRefund) {
        return 'nuvempago_refund_payment_link_total_setup_refund_button_click';
      }
      return 'nuvempago_refund_payment_link_total_setup_refund_button_click';
    } else if (props.caller == Caller.TransactionRefund) {
      if (isCreditCard(refundDetailsData?.paymentMethod ?? '')) {
        if (isTotalRefund) {
          return 'nuvempago_refund_credit_card_total_setup_refund_button_click';
        }
        return 'nuvempago_refund_credit_card_partial_setup_refund_button_click';
      } else if (isPix(refundDetailsData?.paymentMethod ?? '')) {
        if (isTotalRefund) {
          return 'nuvempago_refund_pix_total_setup_refund_button_click';
        }
        return 'nuvempago_refund_pix_partial_setup_refund_button_click';
      }
    }
  }, [isTotalRefund, props.caller, refundDetailsData?.paymentMethod]);

  const refundButtonAmplitudeTag = useMemo(() => {
    if (props.caller == Caller.PaymentLinkRefund) {
      return isTotalRefund
        ? 'nuvempago_refund_payment_link_total_modal_cancel_sale_button_click'
        : 'nuvempago_refund_partial_payment_link_modal_refund_button_click';
    } else if (props.caller == Caller.TransactionRefund) {
      if (isCreditCard(refundDetailsData?.paymentMethod ?? '')) {
        return isTotalRefund
          ? 'nuvempago_refund_credit_card_total_modal_cancel_sale_button_click'
          : 'nuvempago_refund_partial_credit_card_modal_refund_button_click';
      } else if (isPix(refundDetailsData?.paymentMethod ?? '')) {
        return isTotalRefund
          ? 'nuvempago_refund_pix_total_modal_cancel_sale_button_click'
          : 'nuvempago_pix_partial_modal_refund_button_click';
      }
    }
  }, [isTotalRefund, props.caller, refundDetailsData?.paymentMethod]);

  // eslint-disable-next-line max-len
  const [customAlertProps, setCustomAlertProps] = useState<CustomAlertProps>({
    title: '',
    message: '',
    linkTitle: '',
    appearance: undefined,
    testid: '',
  });

  const isPartial = useMemo(() => {
    if (
      refundDetailsData?.remainingAmountToRefund?.value &&
      requestRefundAmount < refundDetailsData?.remainingAmountToRefund?.value
    ) {
      return true;
    }

    return false;
  }, [refundDetailsData?.remainingAmountToRefund?.value, requestRefundAmount]);

  const notificationContext = useContext(NotificationBRContext);

  useEffect(() => {
    navigateHeader(nexo, { goTo: ROUTES.DASHBOARD, text: navT('back') });
    setButtonDisabled(!isValidInputAmount);
    const navigationToDetailMapping: Record<Caller, string> = {
      [Caller.PaymentLinkRefund]: `${ROUTES.PAYMENT_LINK_DETAILS}`,
      [Caller.TransactionRefund]: `${ROUTES.TRANSACTION_DETAILS}`,
    };

    setButtonUrlCancel(
      `${navigationToDetailMapping[props.caller]}/${props.refundId}`,
    );
  }, [isValidInputAmount, navT, props.caller, props.refundId]);

  const canSelectRestockOption = useCallback(() => {
    const paymentMethod = refundDetailsData?.paymentMethod ?? '';
    const isPaymentLink = props.caller === Caller.PaymentLinkRefund;
    return (
      isTotalRefund &&
      !isPaymentLink &&
      (isCreditCard(paymentMethod) || isPix(paymentMethod))
    );
  }, [refundDetailsData, isTotalRefund, props]);

  useEffect(() => {
    let abort = false;

    function navigateToSuccessPage() {
      const navigationToDetailMapping: Record<Caller, string> = {
        [Caller.PaymentLinkRefund]: `${ROUTES.PAYMENT_LINK_DETAILS}`,
        [Caller.TransactionRefund]: `${ROUTES.TRANSACTION_DETAILS}`,
      };
      history.push(
        `${navigationToDetailMapping[props.caller]}/${props.refundId}`,
      );
    }

    async function waitFor(seconds: number) {
      const delayInMilliseconds = seconds * 1000;
      return new Promise((resolve) => setTimeout(resolve, delayInMilliseconds));
    }

    function actionsForSuccess() {
      setLoadingConfirmation(false);
      setOpenConfirmation(false);
      setRefundSuccessful(undefined);
      navigateToSuccessPage();
    }

    function handleSuccess() {
      const delay = props.confirmationDelaySeconds;
      if (delay) {
        waitFor(delay).then(() => {
          if (!abort) actionsForSuccess();
        });
      } else actionsForSuccess();
    }

    function handleFailure() {
      setLoadingConfirmation(false);
      setOpenConfirmation(false);
      setRefundSuccessful(undefined);
    }

    if (isRefundSuccessful === true) handleSuccess();
    else if (isRefundSuccessful === false) handleFailure();

    return () => {
      abort = true;
    };
  }, [history, props, isRefundSuccessful, setLoadingConfirmation]);

  const handleRefundClick = useCallback(
    async (value: number) => {
      setLoadingConfirmation(true);
      if (refundButtonAmplitudeTag) track(refundButtonAmplitudeTag);
      if (canSelectRestockOption() && restock === true)
        track('nuvempago_refund_total_setup_return_stock_checked');

      try {
        await props.onRefund({ value, currency: 'BRL' }, restock);

        setRefundSuccessful(true);

        if (isPartial) {
          notificationContext.pushNotification({
            id: props.refundId,
            type: NotificationType.PartialRefundSuccess,
            content: NotificationType.PartialRefundSuccess,
          });
        } else {
          notificationContext.pushNotification({
            id: props.refundId,
            type: NotificationType.RefundSuccess,
            content: NotificationType.RefundSuccess,
          });
        }
      } catch (error: any) {
        setRefundSuccessful(false);

        switch (error?.data?.failure_code) {
          case FailureCode.InsufficientAccountBalance:
            setCustomAlertProps({
              title: t('alert.not_enough_balance.title'),
              message: t('alert.not_enough_balance.message'),
              appearance: 'danger',
              testid: 'Not-Enough-Balance-Alert',
            });
            break;
          case FailureCode.TransactionDateTooOld:
            if (isPix(refundDetailsData?.paymentMethod ?? '')) {
              setCustomAlertProps({
                title: t('alert.older_than_90_days.title'),
                message: t('alert.older_than_90_days.message'),
                appearance: 'danger',
                testid: '90-days-Alert',
              });
            } else {
              setCustomAlertProps({
                title: t('alert.older_than_180_days.title'),
                message: t('alert.older_than_180_days.message'),
                appearance: 'danger',
                testid: '180-days-Alert',
              });
            }
            break;
          default:
            setCustomAlertProps({
              title: t('alert.generic.title'),
              message: t('alert.generic.message'),
              appearance: 'danger',
              testid: 'Generic-Error-Alert',
            });
            break;
        }
        setIsShowAlert(true);
      }
    },
    [
      refundButtonAmplitudeTag,
      canSelectRestockOption,
      restock,
      props,
      isPartial,
      notificationContext,
      t,
      refundDetailsData?.paymentMethod,
    ],
  );

  const handleAlertCloseClick = useCallback(() => {
    setIsShowAlert(false);
  }, []);

  if (props.isLoading) {
    return (
      <Box>
        {isMobile ? (
          <Box>
            <Refund.SkeletonMobile />
          </Box>
        ) : (
          <Box>
            <Refund.SkeletonDesktop />
          </Box>
        )}
      </Box>
    );
  }

  const confirmationButton = (buttonText: string) => (
    <Button
      data-testid="Handle-Refund"
      appearance="primary"
      autoFocus={true}
      onClick={() => {
        handleRefundClick(requestRefundAmount);
      }}
    >
      {buttonText}
    </Button>
  );

  const loadingConfirmationButton = (buttonText: string) => (
    <Button data-testid="loadingConfirmationButton" appearance="primary">
      <React.Fragment key=".0">
        <Spinner color="currentColor" size="small" />
        {buttonText}
      </React.Fragment>
    </Button>
  );

  const renderConfirmation = () => {
    let title, message, buttonText, shouldRenderBackButton;

    const formatAmount = (amount: number) =>
      (Math.round(amount * 100) / 100).toFixed(2).replace('.', ',');

    if (isTotalRefund && props.caller === Caller.TransactionRefund) {
      title = t('confirmationModal.title_total');
      message = t('confirmationModal.message_confirm');
      buttonText = t('confirmationModal.cancel');
      shouldRenderBackButton = true;
    } else {
      title = t('confirmationModal.title_with_amount', {
        amount: formatAmount(requestRefundAmount),
      });
      message = t('confirmationModal.message_refund');
      buttonText = t('confirmationModal.refund');
      shouldRenderBackButton = false;
    }

    return (
      <Modal
        maxWidth={{
          xs: '343px',
          md: '343px',
          lg: '500px',
          xl: '500px',
        }}
        data-testid="Confirmation-Modal"
        open={openConfirmation}
        onDismiss={handleOpenConfirmation}
      >
        <Modal.Header title={title} />
        <Modal.Body padding="none">
          <Text as="p" fontSize="base" lineHeight="base" textAlign="left">
            {message}
          </Text>
        </Modal.Body>
        <Modal.Footer>
          <Sidebar.Footer>
            {shouldRenderBackButton && (
              <Button
                data-testid="goBackButton"
                onClick={handleOpenConfirmation}
              >
                {t('confirmationModal.back')}
              </Button>
            )}
            {isLoadingConfirmation
              ? loadingConfirmationButton(buttonText)
              : confirmationButton(buttonText)}
          </Sidebar.Footer>
        </Modal.Footer>
      </Modal>
    );
  };

  function getGridTemplateColumns() {
    switch (props.caller) {
      case Caller.PaymentLinkRefund:
        return '[margin-l] 1fr [rcard] 1fr [margin-r]';
      case Caller.TransactionRefund:
        return '[margin-l] [lcard] 2fr [rcard] 1fr [margin-r]';
    }
  }

  function getGridTemplateAreas() {
    switch (props.caller) {
      case Caller.PaymentLinkRefund:
        return `"margin-l hd margin-r"
         "margin-l rcard margin-r"
        "margin-l ft margin-r"`;
      case Caller.TransactionRefund:
        return `"margin-l hd hd margin-r"
        "margin-l lcard rcard margin-r"
       "margin-l ft ft margin-r"`;
    }
  }

  const renderResumeCard = () => (
    <ResumeCard
      paid={refundDetailsData?.prices?.subtotal.value ?? 0}
      totalRefunded={refundDetailsData?.prices?.totalRefundedAmount?.value ?? 0}
      total={refundDetailsData?.prices?.total.value ?? 0}
    />
  );

  const renderDeskTopRefund = () => (
    <Box>
      <Box
        width={'100%'}
        padding={'4'}
        backgroundColor="neutral-surface"
        data-testid="Refund-Page-Component-Desktop"
      >
        <Box
          display="grid"
          gridTemplateAreas={getGridTemplateAreas()}
          gridTemplateColumns={getGridTemplateColumns()}
          alignItems={'flex-start'}
          gap={'6'}
          justifyContent={'center'}
          flexWrap={'wrap'}
          flexDirection={'column'}
          marginTop={'4'}
        >
          <Box display="grid" gridArea={'hd'}>
            {renderOrderNumber()}
            <Box display={isShowAlert ? 'block' : 'none'} marginBottom={'2'}>
              <DetailAlert
                title={customAlertProps.title}
                message={customAlertProps.message}
                linkTitle={customAlertProps.linkTitle}
                linkUrl={customAlertProps?.linkUrl}
                appearance={customAlertProps.appearance}
                onRemove={handleAlertCloseClick}
                dataTestId={customAlertProps.testid}
              />
            </Box>
          </Box>
          <Box
            display="grid"
            gridArea={'lcard'}
            gap={'5'}
            minWidth={{ xs: '343px', md: '400px', lg: '400px', xl: '400px' }}
            maxWidth={'400px'}
          >
            {props.caller == Caller.TransactionRefund &&
              refundDetailsData?.productDetails && (
                <ListProductInfo
                  products={refundDetailsData?.productDetails}
                  total={refundDetailsData?.prices?.total.value}
                  shipping={refundDetailsData?.prices?.shipping.value}
                  subTotal={refundDetailsData?.prices?.subtotal.value}
                  discount={refundDetailsData?.prices?.discount?.value ?? 0}
                  partialRefunded={
                    refundDetailsData?.prices?.totalRefundedAmount.value
                  }
                />
              )}
          </Box>
          <Box
            display="grid"
            gridArea={'rcard'}
            gap={'6'}
            minWidth={{ xs: '343px', md: '400px', lg: '548px', xl: '548px' }}
            maxWidth={'548px'}
          >
            {props.caller == Caller.PaymentLinkRefund && renderResumeCard()}
            {refundDetailsData && (
              <PaymentCard {...mergePaymentData(refundDetailsData)} />
            )}
            <RefundAmountCard
              refundableAmount={refundDetailsData?.prices?.total.value ?? 0}
              paymentMethod={refundDetailsData?.paymentMethod ?? ''}
              setRequestRefundAmount={setRequestRefundAmount}
              setIsValidInputAmount={setIsValidInputAmount}
              setIsTotalRefund={setIsTotalRefund}
            />
            <ShowComponent feature={FeatureFlag.FS_NUVEMPAGO_REFUND_RESTOCK}>
              <>
                {canSelectRestockOption() && (
                  <RestockCard setRestock={setRestock} />
                )}
              </>
            </ShowComponent>
            {renderActionButtons()}
          </Box>
          <Box display="grid" gridArea={'ft'} gap={'8'}>
            <NuvemPagoCta
              href={
                'https://atendimento.nuvemshop.com.br/pt_BR/configuracao-nuvem-pago/como-criar-um-link-de-pagamento-do-nuvem-pago'
              }
              title={t('cta')}
              trackingTag={'nuvempago_refund_can_link_click'}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const renderOrderNumber = () => {
    const invalidOrderNumber = isInvalidOrderNumber(
      refundDetailsData?.orderNumber,
    );
    const title = t('title', {
      callerTitle: props.caller == Caller.TransactionRefund ? 'venda' : 'link',
      orderNumber: formatOrderNumber(refundDetailsData?.orderNumber),
    });

    if (invalidOrderNumber) {
      track('nuvempago_order_not_synchronized');
    }

    return (
      <Title color="primary-textHigh" data-testid={'refund-page-title'}>
        {invalidOrderNumber ? title.replace('#', '') : title}
      </Title>
    );
  };

  const renderActionButtons = () => (
    <Sidebar.Footer>
      <Button
        onClick={() => history.push(buttonUrlCancel)}
        data-testid={'refund-cancel-btn'}
      >
        {t('cancel')}
      </Button>
      <Button
        appearance="primary"
        onClick={() => {
          setOpenConfirmation(true);
          if (setupAmplitudeTag) track(setupAmplitudeTag);
        }}
        disabled={buttonDisabled}
        data-testid={'refund-button-id'}
      >
        {t('refund')}
      </Button>
    </Sidebar.Footer>
  );

  const renderCta = () => (
    <Box
      display={'flex'}
      padding={'4'}
      justifyContent={'center'}
      alignItems={'center'}
      gap={'2'}
      alignSelf={'stretch'}
    >
      <NuvemPagoCta
        href={
          'https://atendimento.nuvemshop.com.br/pt_BR/gestao-dos-pagamentos/como-estornar-uma-venda-atraves-do-nuvem-pago'
        }
        title={t('cta')}
        trackingTag={'nuvempago_refund_can_link_click'}
      />
    </Box>
  );

  const renderMobileRefund = () => (
    <Box
      gap={'4'}
      padding={'4'}
      alignItems={'flex-start'}
      display="flex"
      flexDirection="column"
      data-testid={'Refund-Page-Component-Mobile'}
      backgroundColor="neutral-background"
    >
      <MobileTopNavBar backLinkPath={ROUTES.DASHBOARD} padding="none" />
      <Box>{renderOrderNumber()}</Box>
      <Box display={isShowAlert ? 'block' : 'none'} marginBottom={'2'}>
        <DetailAlert
          title={customAlertProps.title}
          message={customAlertProps.message}
          linkTitle={customAlertProps.linkTitle}
          linkUrl={customAlertProps?.linkUrl}
          appearance={customAlertProps.appearance}
          onRemove={handleAlertCloseClick}
          dataTestId={customAlertProps.testid}
        />
      </Box>

      {props.caller == Caller.TransactionRefund &&
        refundDetailsData?.productDetails && (
          <ListProductInfo
            products={refundDetailsData?.productDetails}
            total={refundDetailsData?.prices?.total.value}
            shipping={refundDetailsData?.prices?.shipping.value}
            subTotal={refundDetailsData?.prices?.subtotal.value}
            discount={refundDetailsData?.prices?.discount?.value ?? 0}
            partialRefunded={
              refundDetailsData?.prices?.totalRefundedAmount.value
            }
          />
        )}
      {props.caller == Caller.PaymentLinkRefund && renderResumeCard()}
      {refundDetailsData && (
        <PaymentCard {...mergePaymentData(refundDetailsData)} />
      )}
      <RefundAmountCard
        refundableAmount={refundDetailsData?.prices?.total.value ?? 0}
        paymentMethod={refundDetailsData?.paymentMethod ?? ''}
        setRequestRefundAmount={setRequestRefundAmount}
        setIsValidInputAmount={setIsValidInputAmount}
        setIsTotalRefund={setIsTotalRefund}
      />
      <ShowComponent feature={FeatureFlag.FS_NUVEMPAGO_REFUND_RESTOCK}>
        <>
          {canSelectRestockOption() && <RestockCard setRestock={setRestock} />}
        </>
      </ShowComponent>
      {renderActionButtons()}
      <Box gap={'6'}></Box>
      {renderCta()}
    </Box>
  );

  return (
    <Box data-testid="Refund-test-id">
      <ToastProvider>
        {renderConfirmation()}
        <RenderOnError
          error={props.isError}
          message={''}
          label={''}
          refreshHandler={() => null}
        >
          {isMobile ? renderMobileRefund() : renderDeskTopRefund()}
        </RenderOnError>
      </ToastProvider>
    </Box>
  );
}

export function SkeletonMobile(): JSX.Element {
  return (
    <Box
      gap={'4'}
      padding={'4'}
      alignItems={'flex-start'}
      display="flex"
      flexDirection="column"
      data-testid="Refund-Skeleton-Mobile"
    >
      <MobileTopNavBar backLinkPath={ROUTES.DASHBOARD} padding="none" />
      <Box>
        <Title.Skeleton width="240px" height="44px" />
      </Box>
      <ListProductInfo.Skeleton />
      <PaymentCard.Skeleton />
      <RefundAmountCard.Skeleton />
      <Sidebar.Footer>
        <Button.Skeleton />
        <Button.Skeleton />
      </Sidebar.Footer>
    </Box>
  );
}

export function SkeletonDesktop(): JSX.Element {
  return (
    <Box
      width={'100%'}
      padding={'4'}
      backgroundColor="neutral-surface"
      data-testid="Refund-Skeleton"
    >
      <Box
        display="grid"
        gridTemplateAreas='"margin-l hd hd margin-r"
                      "margin-l lcard rcard margin-r"
                      "margin-l ft ft margin-r"'
        gridTemplateColumns={'[margin-l] [lcard] 2fr [rcard] 1fr [margin-r]'}
        alignItems={'flex-start'}
        gap={'6'}
        justifyContent={'center'}
        flexWrap={'wrap'}
        flexDirection={'column'}
        marginTop={'4'}
      >
        <Box display="grid" gridArea={'hd'}>
          <Title.Skeleton width="240px" height="44px" />
        </Box>
        <Box
          display="grid"
          gridArea={'lcard'}
          gap={'5'}
          width={{ xs: '400px', md: '400px', lg: '400px', xl: '400px' }}
          maxWidth={'400px'}
        >
          <ListProductInfo.Skeleton />
        </Box>
        <Box
          display="grid"
          gridArea={'rcard'}
          gap={'5'}
          width={{ xs: '400px', md: '400px', lg: '516px', xl: '516px' }}
          maxWidth={'516px'}
        >
          <PaymentCard.Skeleton />
          <RefundAmountCard.Skeleton />
        </Box>
        <Box display="grid" gridArea={'ft'} gap={'8'}>
          <Sidebar.Footer>
            <Button.Skeleton />
            <Button.Skeleton />
          </Sidebar.Footer>
        </Box>
      </Box>
    </Box>
  );
}
Refund.SkeletonMobile = SkeletonMobile;

Refund.SkeletonDesktop = SkeletonDesktop;

export default Refund;
