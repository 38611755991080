import React from 'react';
import { Table } from '@nimbus-ds/components';
import { DataTable } from '@nimbus-ds/patterns';
import { useTranslationWithPrefix } from 'commons/utils';

function TableHeader() {
  const { t } = useTranslationWithPrefix(
    'dashboard.components.dashboardList.columns',
  );

  return (
    <Table.Head>
      <Table.Row backgroundColor={'neutral-surface'}>
        <DataTable.Cell width="60px">{t('type')}</DataTable.Cell>
        <DataTable.Cell width="100px">{t('date')}</DataTable.Cell>
        <DataTable.Cell width="120px">{t('client')}</DataTable.Cell>
        <DataTable.Cell width="120px">{t('paymentMethod')}</DataTable.Cell>
        <DataTable.Cell width="120px">{t('amount')}</DataTable.Cell>
        <DataTable.Cell width="150px">{t('status')}</DataTable.Cell>
      </Table.Row>
    </Table.Head>
  );
}

export default TableHeader;
