import React from 'react';
import {
  Alert,
  Box,
  Card,
  Checkbox,
  Icon,
  Link,
  Radio,
  Text,
  Title,
} from '@nimbus-ds/components';
import { ExternalLinkIcon } from '@nimbus-ds/icons';
import { ErrorMessage } from 'commons/components';
import { useFeatureFlag } from 'commons/hooks';
import { FeatureFlag } from 'commons/services';
import { InstallmentV2Type, MoneyObjectInterface } from 'commons/types';
import { formatPercentage, useTranslationWithPrefix } from 'commons/utils';
import { toSnake } from 'ts-case-convert';
import { InstallmentSectionInactive } from '../shared/InstallmentSectionInactive';
import { ResponsibleStatus } from '../useInstallmentsV2';
import MinInstallmentInput from './MinInstallmentInput';
import MinOrderAmountInput from './MinOrderAmountInput';
import WithoutInterestSkeleton from './WithoutInterestSkeleton';

interface Installments {
  planAhora: InstallmentV2Type[];
  withoutInterest: InstallmentV2Type[];
}

export interface WithoutInterestInterface {
  installments?: Installments;
  setInstallments: (installments: Installments) => void;
  configDisabled: boolean;
  hasError: boolean;
  openSwitchModal: () => void;
  status: ResponsibleStatus;
  minInstallmentValue?: MoneyObjectInterface;
  setMinInstallmentValue?: (value: MoneyObjectInterface) => void;
  updateInstallmentMinOrderAmount: (
    id: number,
    minOrderAmount: MoneyObjectInterface,
  ) => void;
}

export function WithoutInterest({
  installments,
  setInstallments,
  minInstallmentValue,
  setMinInstallmentValue,
  configDisabled,
  hasError,
  openSwitchModal,
  status,
  updateInstallmentMinOrderAmount,
}: WithoutInterestInterface): JSX.Element {
  const { t } = useTranslationWithPrefix('installments.withoutInterest');
  const installmentMinOrderAmountFlag = useFeatureFlag(
    FeatureFlag.FS_PAGONUBE_MIN_ORDER_AMOUNT_ENABLE,
  );

  if (hasError)
    return (
      <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
        <Title as="h4">{t('title')}</Title>
        <ErrorMessage />
      </Box>
    );

  if (installmentMinOrderAmountFlag.loading) return <WithoutInterestSkeleton />;

  const isEnabledIn = (
    installmentList: InstallmentV2Type[],
    installmentQty: number,
  ): boolean => {
    return installmentList.some(
      (item) => item.quantity == installmentQty && item.enabled,
    );
  };

  const couldBeEnabledIn = (
    installmentName: string,
    installmentQty: number,
  ): boolean => {
    if (!installments) return true;

    const installmentTypes = Object.entries(installments);

    const isEnabledInOtherInstallmentType = installmentTypes.some(
      ([name, list]: [string, InstallmentV2Type[]]) =>
        name !== installmentName &&
        name !== toSnake(installmentName) &&
        isEnabledIn(list, installmentQty),
    );

    return !isEnabledInOtherInstallmentType;
  };

  const checkInstallment = (
    name: string,
    quantity: number,
    enabled: boolean,
    installmentList: InstallmentV2Type[],
  ) => {
    if (installments) {
      const index = installmentList.findIndex(
        (installment) => installment.quantity === quantity,
      );
      if (index >= 0) {
        const updatedInstallment = {
          ...installmentList[index],
          enabled,
        };
        const newInstallments = [...installmentList];
        newInstallments[index] = updatedInstallment;
        setInstallments({
          ...installments,
          [name]: newInstallments,
        });
      }
    }
  };

  const installmentLabel = (installment: InstallmentV2Type) => {
    if (installment.quantity === 1) return t('installments.one');

    return installment.interest
      ? t('installments.many', {
          quantity: installment.quantity,
          rate: formatPercentage(installment.interest),
        })
      : t('installments.many_unknown_interest', {
          quantity: installment.quantity,
        });
  };

  const renderInstallments = (
    name: string,
    installmentList: InstallmentV2Type[],
    forceDisable?: boolean,
    helpInfo?: {
      helpText: string;
      helpLink: string;
    },
  ) => (
    <Box
      display="flex"
      alignItems="flex-start"
      flexDirection="column"
      gap="2"
      width="100%"
    >
      <Text color="neutral-textHigh" fontWeight="medium">
        {t(`${name}`)}
      </Text>
      <Box
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
        gap="2-5"
        width="100%"
      >
        {installmentList.length ? (
          installmentList.map((installment, idx) => (
            <Card key={`installment-${name}-${idx}`}>
              <Checkbox
                label={installmentLabel(installment)}
                name={`${name}-${installment.quantity}`}
                checked={installment.enabled}
                onChange={(event) =>
                  checkInstallment(
                    name,
                    installment.quantity,
                    event.currentTarget.checked,
                    installmentList,
                  )
                }
                disabled={
                  forceDisable ||
                  configDisabled ||
                  !couldBeEnabledIn(name, installment.quantity)
                }
              />
              {installment.enabled && installmentMinOrderAmountFlag.enabled && (
                <MinOrderAmountInput
                  value={installment.minOrderAmount}
                  onChange={updateInstallmentMinOrderAmount}
                  installmentId={installment.id}
                  installmentQuantity={installment.quantity}
                />
              )}
            </Card>
          ))
        ) : (
          <>
            <Text color="neutral-textHigh" fontSize="base">
              {' '}
              {t(`empty_${name}`)}{' '}
            </Text>
            {helpInfo && (
              <Link
                as="a"
                href={helpInfo.helpLink}
                appearance="primary"
                target="_blank"
              >
                <Icon color="currentColor" source={<ExternalLinkIcon />} />
                {helpInfo.helpText}
              </Link>
            )}
          </>
        )}
      </Box>
    </Box>
  );

  const InstallmentsSection = (forceDisable?: boolean) => (
    <Box display="flex" alignItems="flex-start" flexDirection="column" gap="4">
      {renderInstallments(
        'planAhora',
        installments?.planAhora || [],
        forceDisable,
        {
          helpText: t('plan_ahora_help_text'),
          helpLink: t('plan_ahora_help_link'),
        },
      )}
      {renderInstallments(
        'withoutInterest',
        installments?.withoutInterest || [],
        forceDisable,
      )}
      {!installmentMinOrderAmountFlag.enabled &&
      minInstallmentValue &&
      setMinInstallmentValue ? (
        <MinInstallmentInput
          value={minInstallmentValue}
          onChange={setMinInstallmentValue}
          disabled={forceDisable}
        />
      ) : null}
    </Box>
  );

  if (status === 'changing_to_customer')
    return (
      <InstallmentSectionInactive translationPrefix="installments.withoutInterest">
        {InstallmentsSection(true)}
      </InstallmentSectionInactive>
    );

  if (status === 'changing_to_merchant')
    return (
      <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
        <Box display="flex" justifyContent="space-between">
          <Title as="h4">{t('title')}</Title>
          <Radio name="without-interest" checked={false} disabled readOnly />
        </Box>
        <Text color="neutral-textHigh" fontSize="base">
          {t('activeSubtitle')}
        </Text>
        <Alert show appearance="neutral">
          {t('changeInProgress')}
        </Alert>
        {InstallmentsSection(true)}
      </Box>
    );

  if (status === 'merchant_active')
    return (
      <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
        <Box display="flex" justifyContent="space-between">
          <Title as="h4">{t('title')}</Title>
          <Radio name="without-interest" checked readOnly />
        </Box>
        <Text color="neutral-textHigh" fontSize="base">
          {t('activeSubtitle')}
        </Text>
        {InstallmentsSection()}
      </Box>
    );

  return (
    <InstallmentSectionInactive
      translationPrefix="installments.withoutInterest"
      openSwitchModal={openSwitchModal}
    />
  );
}
