import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { track } from '@amplitude/analytics-browser';
import {
  Alert,
  Box,
  Card,
  Icon,
  Link,
  Text,
  Title,
} from '@nimbus-ds/components';
import { InvoiceIcon } from '@nimbus-ds/icons';
import { ROUTES } from 'App/Routes/constants';
import {
  ChargesDashboardStatus,
  FailureReason,
} from 'commons/services/dashboardServiceV2/dashboard.service';
import { getStatusTagCode } from 'commons/services/dashboardServiceV2/status-tag.service';
import {
  InstallmentsFeeResponsible,
  TransactionDetailsType,
} from 'commons/types';
import { TransactionOrigin } from 'commons/types/TransactionOrigin.enum';
import { useTranslationWithPrefix } from 'commons/utils';
import { TypeTag } from 'domains/Brazil/commons/components/StatusTag';
import { isCreditCard } from 'domains/Brazil/commons/utils/paymentMethod';
import { isRefunded } from 'domains/Brazil/commons/utils/status';

const getRefundReceiptTrackingTag = (
  status?: string,
  origin?: TransactionOrigin,
): string => {
  const refundType = status === TypeTag.PartiallyRefunded ? 'partial' : 'total';
  const orderType =
    origin === TransactionOrigin.PaymentLink ? 'payment_link' : 'sale';

  return `nuvempago_${orderType}_detail_${refundType}_refund_voucher_click`;
};

export type PaymentCardProps = {
  readonly details: TransactionDetailsType;
};

export function PaymentCard({ details }: PaymentCardProps): JSX.Element {
  const { t: paymentMethodsT } = useTranslationWithPrefix('paymentMethods');
  const { t: paymentT } = useTranslationWithPrefix(
    'transactions.details.payment',
  );
  const history = useHistory();

  function installmentsDescription(
    installments: number,
    feesResponsible: InstallmentsFeeResponsible | undefined,
    interest: number,
  ) {
    if (installments === 1) return paymentT('singleinstallment');

    const installmentsText = paymentT('installments', {
      installment: installments,
    });

    const interestText =
      feesResponsible === InstallmentsFeeResponsible.CUSTOMER || interest > 0
        ? paymentT('consumerInterest')
        : '';

    return `${installmentsText} ${interestText}`;
  }

  const statusTag = getStatusTagCode(
    details.status as ChargesDashboardStatus,
    details.failureReason as FailureReason,
  );
  const failureCode =
    statusTag === TypeTag.RejectedBySecurity
      ? 'card_rejected_by_security'
      : details.transactions[0].reason;

  const goToRefundReceipt = useCallback(() => {
    track(getRefundReceiptTrackingTag(statusTag, details.origin));
    history.push({
      pathname: `${ROUTES.TRANSACTION_DETAILS}/${details.order.id}${ROUTES.REFUND_RECEIPT}`,
      search: '?v=2',
    });
  }, [details, history, statusTag]);

  return (
    <Box
      minWidth="343px"
      padding="none"
      margin="none"
      data-testid="paymentCardTestId"
    >
      <Card>
        <Card.Header title={paymentT('title')} />
        <Card.Body>
          <Box display="flex" flexDirection="column" gap="2-5">
            <Text fontWeight="medium">
              {paymentMethodsT(details.paymentMethod)}
            </Text>
            {isCreditCard(details.paymentMethod) && (
              <Text fontSize="base" textAlign="left" color="neutral-textLow">
                {installmentsDescription(
                  details.order.installments,
                  details.order.installmentsFeeResponsible,
                  details.interest.value,
                )}
              </Text>
            )}
            {isRefunded(statusTag) && (
              <Link
                as="a"
                appearance="primary"
                textDecoration="none"
                role="link_refundreceipt"
                onClick={goToRefundReceipt}
              >
                <Icon color="primary-interactive" source={<InvoiceIcon />} />
                {paymentT(`link_to_receipt`)}
              </Link>
            )}
            {failureCode && <Alert>{paymentT(`error.${failureCode}`)}</Alert>}
          </Box>
        </Card.Body>
      </Card>
    </Box>
  );
}

function Skeleton(): JSX.Element {
  return (
    <Box minWidth="343px" data-testid="paymentCardSkeleton">
      <Card padding="none">
        <Card.Body padding="none">
          <Box display="flex" flexDirection="column" gap="4" padding="4">
            <Box>
              <Title.Skeleton width="79px" height="28px" />
            </Box>
            <Box display="flex" flexDirection="column" gap="2-5">
              <Text.Skeleton width="100%" fontSize="highlight" />
              <Text.Skeleton width="100%" fontSize="highlight" />
            </Box>
          </Box>
        </Card.Body>
      </Card>
    </Box>
  );
}

PaymentCard.Skeleton = Skeleton;

export default PaymentCard;
