import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { syncPathname } from '@tiendanube/nexo/helpers';
import {
  ACTION_NAVIGATE_SYNC,
  NavigateSyncResponse,
} from '@tiendanube/nexo/actions/actions';
import nexo from './nexoClient';

function NexoSyncRoute({ children }: { children: JSX.Element }) {
  const { pathname, search } = useLocation();
  const { push: goTo, replace: replaceTo } = useHistory();

  useEffect(() => {
    const path = search ? `${pathname}${search}` : pathname;
    syncPathname(nexo, path);
  }, [pathname, search]);

  useEffect(() => {
    const unsuscribe = nexo.suscribe(
      ACTION_NAVIGATE_SYNC,
      ({ path, replace }: NavigateSyncResponse) => {
        const { pathname, search } = location;
        const pathWithSearch = search ? `${pathname}${search}` : pathname;
        if (pathWithSearch !== path) {
          replace ? replaceTo(path) : goTo(path);
        }
      },
    );

    return unsuscribe;
  }, [goTo, replaceTo, location]);

  return children;
}
export default NexoSyncRoute;
