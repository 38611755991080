import React, { useState } from 'react';
import { Box, Button, Modal, Text } from '@nimbus-ds/components';
import { useFeatureDiscovery } from 'commons/hooks';
import { FeatureName } from 'commons/services/featureDiscoveryService/featureDiscoveryServiceResponses';
import { useTranslationWithPrefix } from 'commons/utils';

function MinOrderAmountFeatureModal(): JSX.Element {
  const { t } = useTranslationWithPrefix('home.minOrderAmountFeatureModal');
  const featureDiscovery = useFeatureDiscovery(FeatureName.MinOrderAmount);
  const [modalDismissed, setModalDismissed] = useState(false);

  const modalOpen =
    !featureDiscovery.isLoading &&
    !featureDiscovery?.isDiscovered &&
    !modalDismissed;

  const closeModal = () => {
    featureDiscovery.markAsDiscovered.mutate();
    setModalDismissed(true);
  };

  return (
    <Modal open={modalOpen} onDismiss={closeModal}>
      <Modal.Header title={t('title')} />
      <Box alignItems="stretch">
        <Text color="neutral-textHigh">{t('description')}</Text>
      </Box>
      <Modal.Footer>
        <Button
          appearance="primary"
          onClick={closeModal}
          disabled={featureDiscovery.isMutating}
        >
          {t('button')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MinOrderAmountFeatureModal;
