import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Box, Button, Card, Icon, Link, Spinner } from '@nimbus-ds/components';
import { ExternalLinkIcon } from '@nimbus-ds/icons';
import { DataList } from '@nimbus-ds/patterns';
import AppLayout from 'App/components/AppLayout';
import { ROUTES } from 'App/Routes/constants';
import { WarningModal } from 'commons/components';
import { useIdentityValidation, useIsMobile, useToast } from 'commons/hooks';
import { InstallmentsFeeResponsible } from 'commons/types';
import { useTranslationWithPrefix } from 'commons/utils';
import InstallmentsSkeleton from './InstallmentsSkeleton';
import SwitchModal from './SwitchModal';
import { useInstallmentsV2 } from './useInstallmentsV2';
import WithInterest from './WithInterest';
import { WithoutInterest } from './WithoutInterest';

function InstallmentsV2(): JSX.Element {
  const { t } = useTranslationWithPrefix('installments');
  const { showToastError, showToastSuccess } = useToast();
  const history = useHistory();
  const isMobile = useIsMobile();
  const identityValidation = useIdentityValidation();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [switchModalOpen, setSwitchModalOpen] = useState(false);
  const {
    installments,
    isLoadingInstallments,
    isErrorInstallments,
    changed,
    setChanged,
    updateInstallments,
    submitInstallments,
    responsible,
    changeResponsible,
    minInstallmentValue,
    updateMinInstallmentValue,
    updateInstallmentMinOrderAmount,
  } = useInstallmentsV2({
    onResponsibleChange: {
      success: (responsibleChangedTo: InstallmentsFeeResponsible) => {
        showToastSuccess(
          responsibleChangedTo === InstallmentsFeeResponsible.MERCHANT
            ? t('withoutInterestActivated')
            : t('withInterestActivated'),
        );
        setSwitchModalOpen(false);
      },
      error: () => {
        setSwitchModalOpen(false);
        showToastError(t('error'));
      },
    },
  });

  const isLoading = isLoadingInstallments || identityValidation.isLoading;
  const hasError = isErrorInstallments || identityValidation.isError;
  const isWithInterestActive = responsible.status === 'customer_active';
  const responsibleChanging =
    responsible.status === 'changing_to_customer' ||
    responsible.status === 'changing_to_merchant';

  if (isLoading) return <InstallmentsSkeleton />;

  const redirectBack = () => {
    history.push(ROUTES.CONFIG);
  };

  const submit = async () => {
    if (!changed) {
      showToastSuccess(t('success'));
      redirectBack();
      return;
    }
    setButtonDisabled(true);
    try {
      await submitInstallments.mutateAsync();
      showToastSuccess(t('success'));
      redirectBack();
    } catch {
      showToastError(t('error'));
    }
    setChanged(false);
    setButtonDisabled(false);
  };

  const redirectOrWarningModal = () => {
    if (changed) {
      setWarningModalOpen(true);
    } else {
      redirectBack();
    }
  };

  const Buttons = (
    <Box gap="4" display="flex" flexDirection="row">
      <Button onClick={redirectOrWarningModal}>{t('cancel')}</Button>
      <Button appearance="primary" onClick={submit} disabled={buttonDisabled}>
        {buttonDisabled && <Spinner size="small" />}
        {t('save')}
      </Button>
    </Box>
  );

  const MobileSaveLink = !responsibleChanging ? (
    <Link
      appearance="neutral"
      onClick={submit}
      disabled={buttonDisabled}
      as="button"
      textDecoration="none"
    >
      {buttonDisabled && <Spinner size="small" />}
      {t('save')}
    </Link>
  ) : undefined;

  const HelperText = (
    <Box display="flex" justifyContent="center">
      <Link
        appearance="primary"
        as="a"
        href="https://ayuda.tiendanube.com/es_AR/pago-nube/como-activar-las-cuotas-con-pago-nube"
      >
        {t('optionsHelper')}
        <Icon color="currentColor" source={<ExternalLinkIcon />} />
      </Link>
    </Box>
  );

  const InstallmentsContainer = isMobile ? Box : Card;

  return (
    <AppLayout
      title={t('title')}
      subtitle={t('subtitle')}
      footer={isWithInterestActive || responsibleChanging ? undefined : Buttons}
      navBarItems={isMobile ? MobileSaveLink : undefined}
    >
      <Box display="flex" flexDirection="column" gap="12">
        <InstallmentsContainer>
          <DataList bottomDivider={false}>
            <DataList.Row topDivider={false} paddingX="none" paddingTop="none">
              <WithInterest
                hasError={hasError}
                openSwitchModal={() => setSwitchModalOpen(true)}
                status={responsible.status}
              />
            </DataList.Row>
            <DataList.Row paddingX="none" paddingBottom="none">
              <WithoutInterest
                installments={installments}
                setInstallments={updateInstallments}
                minInstallmentValue={minInstallmentValue}
                setMinInstallmentValue={updateMinInstallmentValue}
                configDisabled={
                  identityValidation.data?.status === 'denied' ||
                  responsibleChanging
                }
                hasError={hasError}
                openSwitchModal={() => setSwitchModalOpen(true)}
                status={responsible.status}
                updateInstallmentMinOrderAmount={
                  updateInstallmentMinOrderAmount
                }
              />
            </DataList.Row>
          </DataList>
        </InstallmentsContainer>
        {isWithInterestActive && HelperText}
      </Box>
      <SwitchModal
        open={switchModalOpen}
        activateWithInterest={!isWithInterestActive}
        onDismiss={() => setSwitchModalOpen(false)}
        loading={changeResponsible.isLoading}
        onActivate={() => changeResponsible.mutate()}
      />
      <WarningModal
        exitButtonDisabled={buttonDisabled}
        onDismiss={() => setWarningModalOpen(false)}
        onExit={redirectBack}
        open={warningModalOpen}
      />
    </AppLayout>
  );
}

InstallmentsV2.Skeleton = InstallmentsSkeleton;
export default InstallmentsV2;
