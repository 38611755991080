export function round(value: number) {
  return Math.round(value * 100) / 100;
}

export function percentOf(percent: number, baseNumber: number) {
  return (baseNumber / 100) * percent;
}

export function percentageBetween(smaller: number, larger: number) {
  const result = (smaller / larger) * 100;
  if (isNaN(result) || result === Infinity) return 0;
  return result;
}

/**
 * Convert a value in pixels (`px`) to desktop publishing points (`pt`).
 *
 * A point is related in a fixed 3:4 aspect ratio to the pixel.
 * eg: `16px` -> `12pt`
 */
export const pxToPt = (px: number) => round(percentOf(75, px));
