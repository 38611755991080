import React, { useCallback, useContext, useEffect, useState } from 'react';
import { track } from '@amplitude/analytics-browser';
import { Box, Button, IconButton } from '@nimbus-ds/components';
import { ChevronLeftIcon } from '@nimbus-ds/icons';
import { SideModal } from '@nimbus-ds/patterns';
import { useTranslationWithPrefix } from 'commons/utils';
import DateRangeFilterEnum from 'domains/Brazil/commons/types/DateRangeFilterEnum';
import StatusFilterEnum from 'domains/Brazil/commons/types/TransactionStatusFilterEnum';
import TypeFilterEnum from 'domains/Brazil/commons/types/TransactionTypeFilterEnum';
import DateFilter from 'domains/Brazil/Dashboard/components/filter/DateFilter';
import StatusFilter from 'domains/Brazil/Dashboard/components/filter/StatusFilter';
import TransactionTypeFilter from 'domains/Brazil/Dashboard/components/filter/TransactionTypeFilter';
import DashboardFiltersContext from './DashboardFiltersContext';

interface SideModalFilterInterface {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

function SideModalFilter({
  showModal,
  setShowModal,
}: SideModalFilterInterface): JSX.Element {
  const context = useContext(DashboardFiltersContext);
  const [type, setType] = useState<TypeFilterEnum>(
    context.type ?? TypeFilterEnum.ALL,
  );
  const [dateRange, setDateRange] = useState<DateRangeFilterEnum>(
    context.dateRange ?? DateRangeFilterEnum.ALL,
  );
  const [fromDate, setFromDate] = useState<string>();
  const [toDate, setToDate] = useState<string>();
  const [status, setStatus] = useState<StatusFilterEnum>(StatusFilterEnum.ALL);
  const { t } = useTranslationWithPrefix('home');

  const setAllFilters = () => {
    context.setType(type);
    context.setDateRange(dateRange);
    context.setFromDate(fromDate);
    context.setToDate(toDate);
    context.setStatus(status);
    context.setPage(1);
    setShowModal(false);
  };

  const clearAllFilters = useCallback(() => {
    track('nuvempago_dashboard_filter_click');
    setShowModal(false);
    context.setType(TypeFilterEnum.ALL);
    setType(TypeFilterEnum.ALL);
    context.setDateRange(DateRangeFilterEnum.ALL);
    setDateRange(DateRangeFilterEnum.ALL);
    context.setStatus(StatusFilterEnum.ALL);
    setStatus(StatusFilterEnum.ALL);
    context.setFromDate(undefined);
    setFromDate(undefined);
    context.setToDate(undefined);
    setToDate(undefined);
    context.setCustomSearch(undefined);
    context.setPage(1);
  }, [context, setShowModal]);

  useEffect(() => {
    setType(context.type);
    setDateRange(context.dateRange);
    setFromDate(context.fromDate);
    setToDate(context.toDate);
    setStatus(context.status);
  }, [
    context.dateRange,
    context.fromDate,
    context.status,
    context.toDate,
    context.type,
  ]);

  return (
    <SideModal
      open={showModal}
      onRemove={() => setShowModal(false)}
      title={t('transactionsSearch.title')}
      padding="base"
      paddingHeader="none"
      paddingFooter="none"
      data-testid="side-filter-dashboard"
      maxWidth={{
        xs: '100%',
        md: '340px',
        lg: '340px',
      }}
      zIndex={'100'}
      headerAction={
        <Box alignItems="center" display="flex" gap="1">
          <IconButton
            as="div"
            source={<ChevronLeftIcon />}
            backgroundColor="transparent"
            borderColor="transparent"
            size="16"
            onClick={() => setShowModal(false)}
            data-testid="closeSideModalButton"
          />
        </Box>
      }
    >
      <Box display="flex" flexDirection="column" gap="4">
        <TransactionTypeFilter type={type} setType={setType} />
        <DateFilter
          dateRangeOption={dateRange}
          setRangeDateOption={setDateRange}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
        />
        <StatusFilter status={status} setStatus={setStatus} />
        <Box
          display={'inline-flex'}
          justifyContent={'flex-end'}
          gap={'2'}
          marginTop={'2'}
        >
          <Button onClick={clearAllFilters} data-testid={'clearFilter'}>
            {t('transactionsSearch.cleanFilter')}
          </Button>
          <Button
            onClick={setAllFilters}
            appearance="primary"
            data-testid={'applyFilter'}
          >
            {t('transactionsSearch.filter')}
          </Button>
        </Box>
      </Box>
    </SideModal>
  );
}

export default SideModalFilter;
