import React, { ChangeEvent } from 'react';
import { Box, Icon, Input, Text } from '@nimbus-ds/components';
import { MoneyIcon } from '@nimbus-ds/icons';
import { useStoreInfo } from 'commons/hooks';
import { MoneyObjectInterface } from 'commons/types';
import { formatCurrency, useTranslationWithPrefix } from 'commons/utils';

interface MinOrderAmountInputProps {
  value: MoneyObjectInterface;
  onChange: (id: number, minOrderAmount: MoneyObjectInterface) => void;
  installmentId: number;
  installmentQuantity: number;
  disabled?: boolean;
}

function MinOrderAmountInput(props: MinOrderAmountInputProps) {
  const { t } = useTranslationWithPrefix('installments.minOrderAmountInput');
  const { storeInfo } = useStoreInfo();

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = Number.parseInt(e.target.value);
    if (!e.target.value || newValue <= 0) {
      props.onChange(props.installmentId, {
        currency: props.value.currency,
        value: 0,
      });
    } else {
      props.onChange(props.installmentId, {
        currency: props.value.currency,
        value: newValue,
      });
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap="2">
      <Text color="neutral-textHigh">{t('title')}</Text>
      <Box display="flex" flexDirection="column" gap="2" width="15rem">
        <Input
          placeholder={t('noAmount')}
          append={<Icon source={<MoneyIcon />} />}
          value={props.value.value || ''}
          onChange={onInputChange}
          disabled={props.disabled}
          type="number"
          onKeyDown={(evt) =>
            ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
          }
        />
      </Box>
      {props.value?.value ? (
        <Text color="neutral-textHigh">
          {t('amountByInstallment', {
            value: formatCurrency(
              {
                value: props.value.value / props.installmentQuantity,
                currency: props.value.currency,
              },
              storeInfo,
            ),
            installmentQuantity: props.installmentQuantity,
          })}
        </Text>
      ) : null}
    </Box>
  );
}

export default MinOrderAmountInput;
