import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { track } from '@amplitude/analytics-browser';
import { Alert, Box, Card, Text, Title, useToast } from '@nimbus-ds/components';
import { ROUTES } from 'App/Routes/constants';
import { useIsMobile, useStoreInfo } from 'commons/hooks';
import paymentLinkService, {
  ExpirationTime,
  paymentLinkInfo,
} from 'commons/services/paymentLink/paymentLinkService';
import { useTranslationWithPrefix } from 'commons/utils';
import AppLayout from 'domains/Brazil/AppLayout/AppLayout';
import MobileTopNavBar from 'domains/Brazil/commons/components/MobileTopNavBar';
import AmountInput from './components/AmountField';
import ClientNameInput from './components/ClientNameField';
import DescriptionInput from './components/DescriptionField';
import ExpirationField from './components/ExpirationField';
import { fieldHandler } from './components/FieldHandler.interface';
import FormButtons, { formButtons } from './components/FormButtons';
import PaymentLinkFormSkeleton from './PaymentLinkForm.skeleton';

export interface PaymentLinkFormInterface {
  onSuccess: (paymentLink: paymentLinkInfo) => void;
  onFailure: () => void;
}

interface formFields {
  clientName: fieldHandler;
  expiration: fieldHandler;
  amount: fieldHandler;
  description: fieldHandler;
}

function MobileForm(
  { clientName, amount, expiration, description }: formFields,
  { disabledFlag, submitHandler, submittingFlag }: formButtons,
) {
  const { t } = useTranslationWithPrefix('paymentLink.form.mobile');

  return (
    <Box display="grid" gridGap="4">
      <Title as="h1" color="primary-textHigh">
        {t('title')}
      </Title>
      <Box
        boxSizing="content-box"
        padding="4"
        borderStyle="solid"
        borderRadius="2"
        borderWidth="1"
        borderColor="neutral-surfaceHighlight"
      >
        <Box display="grid" gridGap="4">
          <ClientNameInput
            handler={clientName.handler}
            value={clientName.value}
            title={t('clientNameField.title')}
          />
          <AmountInput
            value={amount.value}
            handler={amount.handler}
            title={t('amountField.title')}
            placeHolder={t('amountField.placeHolder')}
            helpText={t('amountField.helpText')}
          />
          <ExpirationField
            handler={expiration.handler}
            value={expiration.value}
            title={t('expirationField.title')}
          />
          <DescriptionInput
            value={description.value}
            handler={description.handler}
            title={t('descriptionField.title')}
            placeHolder={t('descriptionField.placeHolder')}
          />
        </Box>
      </Box>
      <Alert appearance="neutral" show>
        {t('creditCardOnlyNotificaation')}
      </Alert>
      <FormButtons
        submitHandler={submitHandler}
        disabledFlag={disabledFlag}
        submittingFlag={submittingFlag}
      />
    </Box>
  );
}

function DesktopForm(
  { clientName, amount, expiration, description }: formFields,
  { disabledFlag, submitHandler, submittingFlag }: formButtons,
) {
  const { t } = useTranslationWithPrefix('paymentLink.form.desktop');

  return (
    <Box
      display="grid"
      gap="4"
      justifyContent="center"
      alignItems="stretch"
      width="100%"
      gridTemplateRows="50px auto auto"
      gridTemplateColumns="0.7fr"
    >
      <Title as="h1" color="primary-textHigh">
        {t('title')}
      </Title>
      <Card>
        <Card.Header>
          <Title as="h3">{t('cards.customer')}</Title>
        </Card.Header>
        <Box>
          <ClientNameInput
            handler={clientName.handler}
            value={clientName.value}
            title={t('clientNameField.title')}
          />
        </Box>
      </Card>
      <Card>
        <Card.Header>
          <Title as="h3">{t('cards.payment')}</Title>
        </Card.Header>
        <Box display="flex" flexDirection="column" gap="4">
          <AmountInput
            value={amount.value}
            handler={amount.handler}
            title={t('amountField.title')}
            placeHolder={t('amountField.placeHolder')}
            helpText={t('amountField.helpText')}
          />
          <ExpirationField
            handler={expiration.handler}
            value={expiration.value}
            title={t('expirationField.title')}
          />
          <Alert appearance="neutral" show>
            {t('creditCardOnlyNotificaation')}
          </Alert>
        </Box>
      </Card>
      <Card>
        <Card.Header>
          <Box display="flex" flexDirection="row" gap="1" alignItems="center">
            <Title as="h3">{t('cards.description')}</Title>
            <Text color="neutral-interactive" fontSize="base">
              {t('descriptionField.subTitle')}
            </Text>
          </Box>
        </Card.Header>
        <Box>
          <DescriptionInput
            value={description.value}
            handler={description.handler}
            placeHolder={t('descriptionField.placeHolder')}
          />
        </Box>
      </Card>
      <FormButtons
        submitHandler={submitHandler}
        disabledFlag={disabledFlag}
        submittingFlag={submittingFlag}
      />
    </Box>
  );
}

function PaymentLinkForm({
  onSuccess,
  onFailure,
}: PaymentLinkFormInterface): JSX.Element {
  const { t, i18n } = useTranslationWithPrefix('paymentLink.form');
  const { t: navT } = useTranslationWithPrefix('navigation');
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState('');
  const [clientName, setClientName] = useState('');
  const [expirationOption, setExpirationOption] = useState<number>(
    ExpirationTime.TwoDays,
  );
  const isMobile = useIsMobile();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const { addToast } = useToast();
  const { storeInfo } = useStoreInfo();

  const query = useLocation().search;

  useMemo(() => {
    const convertToMoney = (amountString: string | null) => {
      if (!amountString) return null;

      const isNumber = !isNaN(Number(amountString));

      if (!isNumber) return null;

      const amountInCents = Number(amountString?.replace(/\D/g, ''));

      return (amountInCents / 100).toFixed(2);
    };

    const convertExpiration = (expirationString: string | null) => {
      const expirationNumber = Number(expirationString?.replace(/\D/g, ''));

      const isNumber = !isNaN(expirationNumber);

      if (!isNumber) return null;

      const isValidExpiration =
        Object.values(ExpirationTime).includes(expirationNumber);

      return isValidExpiration ? expirationNumber : null;
    };

    const params = new URLSearchParams(query);
    const amountParam = convertToMoney(params.get('amount'));
    const expirationParam = convertExpiration(params.get('expiration'));
    const clientNameParam = params.get('clientName');
    const descriptionParam = params.get('description');

    if (amountParam) setAmount(amountParam);

    if (clientNameParam) setClientName(clientNameParam);

    if (expirationParam) setExpirationOption(expirationParam);

    if (descriptionParam) setDescription(descriptionParam);
  }, [query]);

  useEffect(() => {
    if (
      Number(amount) > 0 &&
      clientName.trim().length > 0 &&
      !clientName.match('[^a-zA-Z ]')
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [amount, clientName]);

  useEffect(() => {
    i18n?.changeLanguage('pt-BR');
  }, [i18n]);

  const submit = async () => {
    try {
      setSubmitting(true);
      setButtonDisabled(true);

      track('nuvempago_payment_link_creation_link_confirm_button');

      const paymentLinkData = await paymentLinkService.createPaymentLink({
        customerName: clientName.trimEnd(),
        amount: Number(amount),
        description:
          description || `Link de pagamento da loja ${storeInfo.name}`,
        expirationTime: Number(expirationOption),
        paymentSuccessUrl: storeInfo.url,
      });

      onSuccess(paymentLinkData);
    } catch (error) {
      setSubmitting(false);
      setButtonDisabled(false);
      addToast({
        type: 'danger',
        text: t('creationFailure'),
        duration: 4000,
        id: 'payment-link-failure-toast',
      });
      onFailure();
    }
  };

  const formFields = {
    clientName: {
      value: clientName,
      handler: setClientName,
    },
    amount: {
      value: amount,
      handler: setAmount,
    },
    expiration: {
      value: expirationOption,
      handler: setExpirationOption,
    },
    description: {
      value: description,
      handler: setDescription,
    },
  };

  const buttonHandlers: formButtons = {
    submitHandler: submit,
    disabledFlag: buttonDisabled,
    submittingFlag: submitting,
  };

  return (
    <AppLayout>
      <MobileTopNavBar
        paddingX="none"
        paddingTop="none"
        backLinkPath={ROUTES.DASHBOARD}
        backLinkText={navT('back')}
      />
      <Box
        paddingBottom="4"
        display="grid"
        gridGap="4"
        backgroundColor="transparent"
      >
        {isMobile
          ? MobileForm(formFields, buttonHandlers)
          : DesktopForm(formFields, buttonHandlers)}
      </Box>
    </AppLayout>
  );
}

PaymentLinkForm.Skeleton = PaymentLinkFormSkeleton;
export default PaymentLinkForm;
