import React from 'react';
import { Box, Card, Icon, Link, Text, Title } from '@nimbus-ds/components';
import { MailIcon, WhatsappIcon } from '@nimbus-ds/icons';
import { TransactionDetailsType } from 'commons/types';
import { useTranslationWithPrefix } from 'commons/utils';
import { DocumentType } from 'domains/Brazil/commons/types/DocumentType.enum';
import { documentAnonimize } from 'domains/Brazil/commons/utils/document';
import { formatPhoneDDI } from 'domains/Brazil/commons/utils/phone';

export type CustomerCardProps = {
  readonly details: TransactionDetailsType;
};

function CustomerCard({
  details: { customer },
}: CustomerCardProps): JSX.Element {
  const { t } = useTranslationWithPrefix('transactions.details.customer');

  const documentType =
    customer.type === 'individual' ? DocumentType.CPF : DocumentType.CNPJ;
  const fullPhoneNumber =
    `${customer.phone.countryCode}${customer.phone.areaCode}${customer.phone.number}`.replace(
      /\D/g,
      '',
    );

  return (
    <Box minWidth="343px" padding="none" margin="none">
      <Card>
        <Card.Header title={t('title')} />
        <Card.Body>
          <Box display="flex" flexDirection="column" gap="2-5">
            <Text fontSize="base" textAlign="left" color="neutral-textLow">
              {customer.name}
            </Text>
            <Box display="flex" flexDirection="row">
              <Link
                textDecoration="none"
                appearance="primary"
                as="a"
                target="_blank"
                href={`mailto:${customer.email}`}
              >
                <Icon color="primary-interactive" source={<MailIcon />} />
                {customer.email}
              </Link>
            </Box>
            <Box display="flex" flexDirection="row">
              <Link
                textDecoration="none"
                appearance="primary"
                as="a"
                href={`https://wa.me/${fullPhoneNumber}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon color="primary-interactive" source={<WhatsappIcon />} />
                {formatPhoneDDI(fullPhoneNumber)}
              </Link>
            </Box>
            <Text fontSize="base" textAlign="left" color="neutral-textLow">
              {t('document', {
                type: documentType.toUpperCase(),
                document: documentAnonimize(customer.document),
              })}
            </Text>
          </Box>
        </Card.Body>
      </Card>
    </Box>
  );
}

function Skeleton(): JSX.Element {
  return (
    <Box minWidth="343px" data-testid="customerCardSkeleton">
      <Card padding="none">
        <Card.Body padding="none">
          <Box display="flex" flexDirection="column" gap="4" padding="4">
            <Box>
              <Title.Skeleton width="79px" height="28px" />
            </Box>
            <Box display="flex" flexDirection="column" gap="2-5">
              <Text.Skeleton width="100%" fontSize="highlight" />
              <Text.Skeleton width="100%" fontSize="highlight" />
              <Text.Skeleton width="100%" fontSize="highlight" />
              <Text.Skeleton width="100%" fontSize="highlight" />
            </Box>
          </Box>
        </Card.Body>
      </Card>
    </Box>
  );
}

CustomerCard.Skeleton = Skeleton;

export default CustomerCard;
