import React from 'react';
import { useLocation } from 'react-router';
import DetailsV1 from './v1';
import DetailsV2 from './v2';

export default function TransactionDetails(): JSX.Element {
  const { search } = useLocation();
  const useV2 = new URLSearchParams(search).get('v') === '2';

  return useV2 ? <DetailsV2 /> : <DetailsV1 />;
}
