import { TypeTag } from 'domains/Brazil/commons/components/StatusTag';
import { ChargesDashboardStatus, FailureReason } from './dashboard.service';

const statusMap = new Map<ChargesDashboardStatus, TypeTag>([
  [ChargesDashboardStatus.Paid, TypeTag.Paid],
  [ChargesDashboardStatus.Refunded, TypeTag.Refunded],
  [ChargesDashboardStatus.PartiallyRefunded, TypeTag.PartiallyRefunded],
  [ChargesDashboardStatus.Chargebacked, TypeTag.Contested],
  [ChargesDashboardStatus.ChargebackDispute, TypeTag.Contested],
  [ChargesDashboardStatus.Failed, TypeTag.Failed],
  [ChargesDashboardStatus.Pending, TypeTag.Pending],
  [ChargesDashboardStatus.Authorized, TypeTag.Authorized],
]);

export function getStatusTagCode(
  status: ChargesDashboardStatus,
  failureReason?: FailureReason,
) {
  let tagCode = statusMap.get(status) ?? TypeTag.NotDefined;

  if (status === ChargesDashboardStatus.Failed) {
    tagCode =
      failureReason === FailureReason.rejectedBySecurity
        ? TypeTag.RejectedBySecurity
        : TypeTag.Failed;
  }

  return tagCode;
}
