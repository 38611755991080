import React from 'react';
import { useHistory } from 'react-router';
import { Alert, Box, Button, Radio, Title } from '@nimbus-ds/components';
import { ROUTES } from 'App/Routes/constants';
import { MultilineText } from 'commons/components/MultilineText';
import { useIdentityValidation } from 'commons/hooks';
import { useTranslationWithPrefix } from 'commons/utils';

export function InstallmentSectionInactive(
  props: React.PropsWithChildren<{
    translationPrefix:
      | 'installments.withInterest'
      | 'installments.withoutInterest';
    openSwitchModal?: () => void;
  }>,
) {
  const { t } = useTranslationWithPrefix(props.translationPrefix);
  const history = useHistory();
  const identityValidation = useIdentityValidation();

  const redirectToValidateIdentity = () => {
    history.push(ROUTES.CONFIG);
  };

  const appearanceByStatus = () => {
    switch (identityValidation.data?.status) {
      case 'pending_review':
        return 'neutral';
      default:
        return 'warning';
    }
  };
  return (
    <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
      <Box display="flex" justifyContent="space-between">
        <Title as="h4">{t('title')}</Title>
        <Radio
          name="inactive-section"
          checked={!props.openSwitchModal}
          readOnly={!props.openSwitchModal}
          onClick={
            identityValidation.data?.status === 'active'
              ? props.openSwitchModal
              : undefined
          }
        />
      </Box>
      <MultilineText color="neutral-textHigh">
        {t('inactiveSubtitle')}
      </MultilineText>
      {props.children}
      {identityValidation.data?.status &&
        identityValidation.data?.status !== 'active' && (
          <Alert show appearance={appearanceByStatus()}>
            {t(`${appearanceByStatus()}.description`)}
            {appearanceByStatus() === 'warning' && (
              <Button appearance="neutral" onClick={redirectToValidateIdentity}>
                {t(`${appearanceByStatus()}.validateButton`)}
              </Button>
            )}
          </Alert>
        )}
    </Box>
  );
}
