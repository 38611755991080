import React from 'react';
import { Icon, Tag, Text } from '@nimbus-ds/components';
import { MoneyIcon } from '@nimbus-ds/icons';
import { useTranslationWithPrefix } from 'commons/utils';

type TagContent = {
  appearance: React.ComponentProps<typeof Tag>['appearance'];
  message: string;
};

export enum TypeTag {
  Paid = 'PAID',
  Refunded = 'REFUNDED',
  PartiallyRefunded = 'PARTIALLYREFUNDED',
  Contested = 'CHARGEBACKED',
  RejectedBySecurity = 'REJECTEDBYSECURITY',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Authorized = 'AUTHORIZED',
  NotDefined = 'UNKNOWN',
}

const typeTagMap = new Map<string, TagContent | null>([
  [TypeTag.Paid, { appearance: 'success', message: 'approved' }],
  [TypeTag.Refunded, { appearance: 'neutral', message: 'refunded' }],
  [
    TypeTag.PartiallyRefunded,
    { appearance: 'neutral', message: 'partiallyRefunded' },
  ],
  [TypeTag.Contested, { appearance: 'neutral', message: 'contested' }],
  [
    TypeTag.RejectedBySecurity,
    { appearance: 'danger', message: 'rejectedBySecurity' },
  ],
  [TypeTag.Failed, { appearance: 'danger', message: 'rejectedByIssuer' }],
  [TypeTag.Pending, { appearance: 'warning', message: 'pending' }],
  [TypeTag.Authorized, { appearance: 'warning', message: 'pending' }],
  [TypeTag.NotDefined, null],
]);

interface StatusParams {
  type: TypeTag | string | undefined;
}

function StatusTag({ type }: StatusParams): JSX.Element {
  const { t } = useTranslationWithPrefix('statusTag');

  const content = typeTagMap.get(type?.toUpperCase() ?? TypeTag.NotDefined);

  if (!content) return <></>;

  const { appearance, message } = content;

  return (
    <Tag appearance={appearance} data-testid="status-tag">
      <Icon color="currentColor" source={<MoneyIcon size={14} />} />
      <Text
        as="span"
        color="currentColor"
        fontSize="caption"
        fontWeight="regular"
        lineHeight="caption"
        textAlign="left"
      >
        {t(message)}
      </Text>
    </Tag>
  );
}

function Skeleton(): JSX.Element {
  return <Tag.Skeleton width="70%" height="20px" />;
}

StatusTag.Skeleton = Skeleton;

export default StatusTag;
