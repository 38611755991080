import React from 'react';
import { useHistory } from 'react-router';
import { Box, Link, Text } from '@nimbus-ds/components';
import { ROUTES } from 'App/Routes/constants';
import { WithdrawObjectInterface } from 'commons/types';
import { useTranslationWithPrefix } from 'commons/utils';
import { getErrorFromStatusCode } from './getErrorFromStatusCode';

export default function WithdrawDetails({
  bankName,
  accountNumber,
  statusCode,
}: {
  bankName: string;
  accountNumber: string;
  statusCode?: WithdrawObjectInterface['statusCode'];
}) {
  const { push } = useHistory();
  const { t } = useTranslationWithPrefix('withdraws.withdrawsTable');
  const errorCode = getErrorFromStatusCode(statusCode);
  const errorCodeText = (
    <Text color="danger-textLow">{t(`statusCode.${errorCode}`)}</Text>
  );

  if (statusCode === 'tax_id_does_not_match_bank_account') {
    return (
      <>
        <Box marginBottom="2">
          {errorCodeText}
          <Link
            appearance="primary"
            textDecoration="none"
            onClick={() => push(ROUTES.BANK_ACCOUNT_CONFIG)}
          >
            {t(`editAccount`)}
          </Link>
        </Box>
        <Text>{t('try_bank', { bankName })}</Text>
        <Text>
          {t('cbu')}: {accountNumber}
        </Text>
      </>
    );
  }

  return (
    <>
      {errorCode ? <Box marginBottom="2">{errorCodeText}</Box> : null}
      <Text>
        {t('bank')} {bankName}
      </Text>
      <Text>
        {t('cbu')}: {accountNumber}
      </Text>
    </>
  );
}
