import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { Box, Button } from '@nimbus-ds/components';
import { ROUTES } from 'App/Routes/constants';
import { useTranslationWithPrefix } from 'commons/utils';

export interface ControlsProps {
  disabled?: boolean;
  amplitudeTag?: string;
  submitHandler: (event?: React.MouseEvent<HTMLButtonElement>) => unknown;
}

const Controls: React.VFC<Readonly<ControlsProps>> = ({
  disabled,
  submitHandler,
}) => {
  const { t } = useTranslationWithPrefix('withdraw');
  const history = useHistory();

  const cancelClickHandler = useCallback(
    () => history.push(ROUTES.DASHBOARD),
    [history],
  );

  const continueClickHandler = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      submitHandler(event);
    },
    [submitHandler],
  );

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
      gap="2"
      data-testid="WithdrawAmountForm-Controls"
    >
      <Button onClick={cancelClickHandler} data-testid="actCancel">
        {t('actCancel')}
      </Button>
      <Button
        appearance="primary"
        onClick={continueClickHandler}
        disabled={disabled}
        data-testid="actContinue"
      >
        {t('actContinue')}
      </Button>
    </Box>
  );
};

export default Controls;
