import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { FeatureFlag, featureFlagService } from 'commons/services';

interface IProps {
  featureFlag: FeatureFlag;
  children?: React.ReactNode;
}

export function FeatureFlagged(props: IProps) {
  const { isSuccess, data } = useQuery([`${props.featureFlag}Query`], () =>
    featureFlagService.isFeatureFlagEnabled(props.featureFlag),
  );
  const flagEnabled = isSuccess && data;
  return flagEnabled ? <React.Fragment>{props.children}</React.Fragment> : null;
}
