import React from 'react';
import { Text } from '@nimbus-ds/components';

export function MultilineText({
  children,
  ...rest
}: { children: string } & React.ComponentProps<typeof Text>) {
  return (
    <React.Fragment>
      {children
        .split('\n')
        .filter((s) => !!s)
        .map((t, i) => (
          <Text key={i} {...rest}>
            {t}
          </Text>
        ))}
    </React.Fragment>
  );
}
