/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { track } from '@amplitude/analytics-browser';
import {
  Alert,
  Box,
  Button,
  Card,
  IconButton,
  Spinner,
  Text,
  Title,
} from '@nimbus-ds/components';
import { EditIcon } from '@nimbus-ds/icons';
import { Layout, Page } from '@nimbus-ds/patterns';
import { ROUTES } from 'App/Routes/constants';
import Polling from 'commons/components/Polling/Polling';
import { InterceptorStatusReason } from 'commons/enums/InterceptorStatusReason.enum';
import { useIsMobile } from 'commons/hooks';
import {
  FeatureFlag,
  featureFlagService,
  merchantService,
} from 'commons/services';
import { WithdrawDto } from 'commons/services/withdrawsService/dtos/withdraw.dto';
import { MoneyObjectInterface } from 'commons/types';
import { SettlementStatus } from 'commons/types/WithdrawStatusType';
import { useTranslationWithPrefix } from 'commons/utils';
import MobileTopNavBar from 'domains/Brazil/commons/components/MobileTopNavBar';
import NuvemPagoCta from 'domains/Brazil/commons/components/NuvemPagoCta';
import TwoFAModal from 'domains/Brazil/commons/components/TwoFAModal';
import {
  documentMask,
  documentType,
} from 'domains/Brazil/commons/utils/document';
import {
  NotificationBRContext,
  NotificationType,
} from 'domains/Brazil/Notifications/Notification.context';
import { WithdrawalPageArgs } from '../../withdrawal';

interface BankAccountInterface {
  holderName: string;
  holderDocument: string;
  bankName: string;
  branchNumber: string;
  branchCheckDigit: string;
  accountNumber: string;
  accountCheckDigit: string;
}

interface InfoReviewInterface {
  withdrawAmount: MoneyObjectInterface;
  bankAccount: BankAccountInterface;
  amplitudeTag?: string;
  callback: (status: SettlementStatus, twoFANewFlow?: boolean) => void;
}

function centsToDecimal(amount: number) {
  return Number((amount / 100).toFixed(2));
}

function WithdrawAmountCard({
  amount,
  nextPage = () => null,
}: { amount: MoneyObjectInterface } & WithdrawalPageArgs) {
  const editWithdrawAmount = useCallback(() => {
    track('nuvempago_transfer_edit_value_click');
    nextPage('form');
  }, [nextPage]);

  return (
    <Card>
      <Card.Header>
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Title as="h3" data-testid="withdrawAmount">
            {`${Intl.NumberFormat('pt-br', {
              style: 'currency',
              currency: amount.currency,
            }).format(centsToDecimal(amount.value))}`}
          </Title>
          <IconButton
            onClick={editWithdrawAmount}
            size="2rem"
            source={<EditIcon size="small" />}
            data-testid="editWithdrawAmountButton"
          />
        </Box>
      </Card.Header>
    </Card>
  );
}

function BankAccountCard({
  bankAccount,
  updateBankAccount,
}: Readonly<{
  bankAccount: BankAccountInterface;
  updateBankAccount: () => void;
}>): JSX.Element {
  const {
    holderName,
    holderDocument,
    bankName,
    branchNumber,
    branchCheckDigit,
    accountNumber,
    accountCheckDigit,
  } = bankAccount;
  const { t } = useTranslationWithPrefix('withdraw.bankAccount');

  const editBankAccount = useCallback(() => {
    track('nuvempago_transfer_edit_account_click');
    updateBankAccount();
  }, [updateBankAccount]);

  return (
    <Card>
      <Card.Header>
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Title as="h3">{holderName}</Title>
          <IconButton
            onClick={editBankAccount}
            size="2rem"
            source={<EditIcon size="small" />}
            data-testid="editBankAccountButton"
          />
        </Box>
      </Card.Header>
      <Card.Body>
        <Box display="flex" flexDirection="column" gap="1">
          <Text>
            {t('holderDocument', {
              type: documentType(holderDocument).toUpperCase(),
              holderDocument: documentMask(holderDocument),
              interpolation: { escapeValue: false },
            })}
          </Text>
          <Text>{t('bank', { bank: bankName })}</Text>
          <Text>
            {t('branch', {
              branch: `${branchNumber}${
                branchCheckDigit ? `-${branchCheckDigit}` : ''
              }`,
            })}
          </Text>
          <Text>
            {t('account', {
              account: `${accountNumber}${
                accountCheckDigit ? `-${accountCheckDigit}` : ''
              }`,
            })}
          </Text>
        </Box>
      </Card.Body>
    </Card>
  );
}

const DIRECT_STATUS = [SettlementStatus.Approved, SettlementStatus.Reproved];

function isBankAccountDefault({
  branchNumber,
  accountNumber,
}: BankAccountInterface) {
  return (
    !branchNumber ||
    !accountNumber ||
    (Number(branchNumber) == 0 && Number(accountNumber) == 0)
  );
}

export default function InfoReview({
  withdrawAmount,
  bankAccount,
  amplitudeTag,
  callback,
  nextPage = () => null,
}: InfoReviewInterface & WithdrawalPageArgs): JSX.Element {
  const { t } = useTranslationWithPrefix('withdraw');
  const isMobile = useIsMobile();
  const history = useHistory();
  const notificationContext = useContext(NotificationBRContext);
  const [openModal, setOpenModal] = useState(false);
  const [shouldStopPolling, setShouldStopPolling] = useState(false);
  const [withdraw, setWithdraw] = useState<WithdrawDto>();
  const [startPolling, setStartPolling] = useState(false);
  const [withdrawStatus, setWithdrawStatus] = useState<SettlementStatus>();
  const [is2FA, setIs2FA] = useState(false);
  const [twoFANewFlowEnabled, setTwoFANewFlowEnabled] = useState(false);
  const redirectToDashboard = () => history.push(ROUTES.DASHBOARD);

  const makeWithdrawal = useCallback(() => {
    track(amplitudeTag ?? 'nuvempago_transfer_data_review_continue_click');
    if (withdraw && is2FA) {
      setOpenModal(true);
      return;
    }
    if (withdraw) return;

    if (!twoFANewFlowEnabled) {
      callback(SettlementStatus.Approved, twoFANewFlowEnabled);
      return;
    }

    merchantService
      .transfer(withdrawAmount)
      .then((withdrawResponse) => {
        setWithdraw(withdrawResponse);
        setStartPolling(true);
      })
      .catch((err) => {
        notificationContext.pushNotification({
          id: `withdrawal-${withdrawAmount?.value}`,
          type: NotificationType.WithdrawalFailed,
          appearance: 'danger',
          title: t('notifications.unexpectedError.title'),
          content: t('notifications.unexpectedError.content', {
            amount: new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(withdrawAmount!.value / 100),
          }),
        });

        history.push(ROUTES.DASHBOARD);
      });
  }, [
    callback,
    history,
    is2FA,
    notificationContext,
    t,
    twoFANewFlowEnabled,
    withdraw,
    withdrawAmount,
    amplitudeTag,
  ]);

  useEffect(() => {
    if (bankAccount && isBankAccountDefault(bankAccount)) {
      nextPage('bank-account-form');
    }

    const twoFANewFlow = async () => {
      const response = await featureFlagService.isFeatureFlagEnabled(
        FeatureFlag.FS_NUVEMPAGO_2FA_ENABLED,
      );
      setTwoFANewFlowEnabled(response);
    };
    twoFANewFlow();
  }, [bankAccount, nextPage, twoFANewFlowEnabled]);

  const SHOULD_CALL_POLLING =
    (!withdrawStatus || withdrawStatus === SettlementStatus.OnHold) &&
    startPolling &&
    twoFANewFlowEnabled;

  const handleSuccess = useCallback(
    (data: WithdrawDto) => {
      const status = data?.antifraudResponse?.status as SettlementStatus;
      const statusReason = data?.antifraudResponse
        ?.statusCode as InterceptorStatusReason;

      setWithdrawStatus(status);

      if (!status) return;

      if (DIRECT_STATUS.includes(status)) {
        setOpenModal(false);
        setShouldStopPolling(true);
        callback(status, twoFANewFlowEnabled);
        return shouldStopPolling;
      }

      if (
        status === SettlementStatus.OnHold &&
        statusReason === InterceptorStatusReason.ON_HOLD_BY_2FA
      ) {
        if (!openModal) setOpenModal(true);
        setStartPolling(false);
        setIs2FA(true);
        setShouldStopPolling(true);
        return true;
      }

      if (status === SettlementStatus.OnHold) {
        setShouldStopPolling(true);
        return shouldStopPolling;
      }
      callback(status, twoFANewFlowEnabled);
      return shouldStopPolling;
    },
    [callback, openModal, shouldStopPolling, twoFANewFlowEnabled],
  );

  const handleError = useCallback(() => {
    setShouldStopPolling(true);
  }, []);

  const fetchTransfer = useCallback(async () => {
    if (withdraw?.id) {
      return merchantService.retrieveTransfer(withdraw.id);
    }
  }, [withdraw]);

  useEffect(() => {
    if (SHOULD_CALL_POLLING) {
      Polling({
        onSuccess: handleSuccess,
        onError: handleError,
        requester: fetchTransfer,
      });
    }
  }, [SHOULD_CALL_POLLING, fetchTransfer, handleError, handleSuccess]);

  return (
    <Box
      backgroundColor={isMobile ? 'neutral-background' : 'neutral-surface'}
      maxWidth="100vw"
      minHeight="100vh"
      padding="none"
      margin="none"
    >
      {isMobile && <MobileTopNavBar backLinkPath={ROUTES.DASHBOARD} />}
      <Page
        data-testid="WithdrawAmountReview-Grid"
        maxWidth="800px"
        height="100%"
      >
        <Page.Header
          title={t('InfoReviewTitle')}
          paddingX={isMobile ? '4' : '6'}
        />
        <Page.Body paddingX={isMobile ? '4' : '6'}>
          <Layout columns="1">
            <Layout.Section>
              <TwoFAModal
                open={openModal}
                setModalState={(state: boolean) => setOpenModal(state)}
                onDismiss={() => setOpenModal(false)}
                withdrawId={withdraw?.id}
                onSubmitSuccess={function (): void {
                  setOpenModal(false);
                  setStartPolling(true);
                }}
                onSubmitError={function (): void {
                  setOpenModal(true);
                }}
              />
              <WithdrawAmountCard amount={withdrawAmount} nextPage={nextPage} />
              <BankAccountCard
                bankAccount={bankAccount}
                updateBankAccount={() => nextPage('bank-account-form')}
              />
              <Alert appearance="neutral" title={t('alert.title')}>
                {t('alert.message')}
              </Alert>
              <Box display="flex" gap="2" justifyContent="flex-end">
                <Button
                  onClick={redirectToDashboard}
                  data-testid="cancelButton"
                >
                  {t('actCancel')}
                </Button>
                <Button
                  disabled={startPolling}
                  onClick={makeWithdrawal}
                  appearance="primary"
                  data-testid="continueButton"
                >
                  {startPolling && (
                    <Spinner color="currentColor" size="small" />
                  )}
                  {t('actContinue')}
                </Button>
              </Box>
              <NuvemPagoCta
                href={
                  'https://atendimento.nuvemshop.com.br/pt_BR/gestao-dos-pagamentos/como-resgatar-saldo-do-nuvem-pago'
                }
                title={t('cta')}
                trackingTag="nuvempago_transfer_can_link_click"
              />
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>
    </Box>
  );
}
